import React from 'react';
import Page from '../Utilities/Page';
import Header from '../Utilities/Header';
import Main from './Main';
import { heroOpts } from '../../constants/heroOpts';

const Home = () => {
  const options = heroOpts.get('home');

  return (
    <Page
      hero={
        <Header
          img={options.img}
          height={90}
          title={options.title}
          text={options.text}
          home={true}
        />
      }
      main={<Main />}
    />
  );
};

export default Home;
