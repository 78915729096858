import React from 'react';
import { useDispatch } from 'react-redux';
import { updateTopLists } from '../../redux/slices/spotify';
import { updateTop5 } from '../../redux/slices/chartify';
import { renderPlaylist } from '../../functions/renderPlaylist';

// ! this can be refactored to reuse PlaylistCardBack.js. renderBackCard is the same function as the one used in PlaylistCardBack except that it also updates the top5 chartify state using the handleSubmit helper.

const UpdateCardBack = ({ spotify }) => {
  // * ----- HOOKS -----
  const dispatch = useDispatch();

  // * ----- HELPERS -----
  const handleSubmit = (spotify) => {
    const top5 = spotify.playlist.slice(0, 5).map((song) => {
      return {
        rank: song.rank,
        artist: song.artist,
        title: song.title,
        imageURL: song.image,
      };
    });

    const obj = { date: spotify.selectedDate, top5 };

    // * dispatch updateTop5
    dispatch(updateTop5(obj));

    // * dispatch updateTopLists
    dispatch(updateTopLists(spotify));
  };

  // * ----- RENDER -----
  const renderBackCard = (playlist) => {
    switch (playlist) {
      case null:
        return '';
      default:
        return (
          <div className='card bg-gradient-secondary custom-card--back'>
            <div className='card-body playlist-preview--card d-flex flex-column'>
              <div>
                <h6 className='card-category text-primary'>Chartify</h6>
                <div href='#pablo'>
                  <h3 className='card-title text-info'>
                    Billboard Top 40 Preview
                  </h3>
                </div>

                <div className='table-responsive playlist-preview--table'>
                  {renderPlaylist(playlist)}
                </div>
              </div>
              <div className='mt-4 d-flex flex-column align-items-center'>
                <p className='text-white'>Updates Top 10, 20 & 40 playlists</p>
                <div className=''>
                  <button
                    onClick={() => {
                      handleSubmit(spotify);
                    }}
                    className='btn btn-primary mt-2'
                    type='submit'
                    name='action'
                    style={{ marginTop: '20px' }}
                  >
                    Update Playlists
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
    }
  };

  return renderBackCard(spotify.playlist);
};

export default UpdateCardBack;
