// * render options for navbar links
export const navLinkOpts = [
  {
    to: 'admin',
    icon: 'admin_panel_settings',
    text: 'Admin',
    auth: true,
    admin: true,
  },
  {
    to: 'dashboard',
    icon: 'space_dashboard',
    text: 'Dashboard',
    auth: true,
  },
  {
    to: 'playlists',
    icon: 'headphones',
    text: 'Playlists',
    auth: true,
  },
  {
    to: 'blog',
    icon: 'text_snippet',
    text: 'Blog',
    auth: false,
  },
  {
    to: 'support',
    icon: 'redeem',
    text: 'Support',
    auth: false,
  },
];
