// import Component from '../components/material-kit/Home/Component';

export const renderPosts = (articles, Component, classes) => {
  const posts = articles.map((article, index) => {
    if (index === 0)
      return <Component article={article} key={index} classes={classes} />;
    return <Component article={article} key={index} />;
  });

  return posts;
};
