import React from 'react';
import axios from 'axios';
import ArticleDate from './ArticleDate';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import TextError from './TextError';

// * formik variables
const initialValues = {
  category: '',
  title: '',
  author: '',
  body: '',
  photo: null,
  date: new Date(),
};

const validate = (values) => {
  let errors = {};

  if (!values.title) {
    errors.title = 'Required';
  } else if (values.title.length > 35) {
    errors.title = 'Max length 35 characters';
  }

  if (!values.body) {
    errors.body = 'Required';
  } else if (values.body.length > 700) {
    errors.body = 'Max length 700 characters';
  }

  if (!values.category) {
    errors.category = 'Required';
  }

  if (!values.author) {
    errors.author = 'Required';
  }

  if (!values.photo) {
    errors.photo = 'Required';
  }

  return errors;
};

// * component
const ArticleForm = () => {
  const onSubmit = async (values) => {
    console.log('Form Values:', values);
    const formData = new FormData();

    formData.append('category', values.category);
    formData.append('title', values.title);
    formData.append('author', values.author);
    formData.append('body', values.body);
    formData.append('playlistURI', values.playlistURI);
    formData.append('photo', values.photo);
    formData.append('date', values.date);

    try {
      const response = await axios.post('/api/articles', formData);
      console.log('response');
      return response.data;
    } catch (error) {
      console.log(error.message);
      return error.message;
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >
      <Form id='article' autoComplete='off'>
        <div className='card-body pb-2'>
          <div className='row'>
            {/* radio buttons */}
            <div className='row'>
              <label>Category</label>
              <div className='col d-flex'>
                <div className='form-check me-3 my-auto'>
                  <Field
                    className='form-check-input'
                    type='radio'
                    name='category'
                    value='Hot 100'
                  />
                  <label
                    className='form-check-label mb-0 ms-0 px-2'
                    htmlFor='flexRadioDefault1'
                  >
                    Hot 100
                  </label>
                </div>
              </div>
              <div className='col d-flex'>
                <div className='form-check me-3 my-auto'>
                  <Field
                    className='form-check-input'
                    type='radio'
                    name='category'
                    value='Featured'
                  />
                  <label
                    className='form-check-label mb-0 ms-0 px-2'
                    htmlFor='flexRadioDefault1'
                  >
                    Featured
                  </label>
                </div>
              </div>
              <div className='col d-flex'>
                <div className='form-check me-3 my-auto'>
                  <Field
                    className='form-check-input'
                    type='radio'
                    name='category'
                    value='Ongoing'
                  />
                  <label
                    className='form-check-label mb-0 ms-0 px-2'
                    htmlFor='flexRadioDefault1'
                  >
                    Ongoing
                  </label>
                </div>
              </div>
              <div className='col d-flex'>
                <div className='form-check me-3 my-auto'>
                  <Field
                    className='form-check-input'
                    type='radio'
                    name='category'
                    value='Chartify'
                  />
                  <label
                    className='form-check-label mb-0 ms-0 px-2'
                    htmlFor='flexRadioDefault1'
                  >
                    Chartify
                  </label>
                </div>
              </div>
              <ErrorMessage name='category' component={TextError} />
            </div>

            {/* author */}
            <div className='row mt-4'>
              <label>Author</label>
              <Field className='col-4 mx-2' name='author' as='select'>
                <option value=''>Select author...</option>
                <option className='dropdown-item' value='chartify'>
                  Chartify
                </option>
              </Field>
              <ErrorMessage name='author' component={TextError} />
            </div>

            {/* title */}
            <div className='row my-4'>
              <div className='col-md-6'>
                <div className='input-group input-group-static '>
                  <label>Title</label>
                  <Field
                    className='form-control'
                    aria-label='title'
                    type='text'
                    placeholder='max length 40 chars'
                    name='title'
                  />
                  <ErrorMessage name='title' component={TextError} />
                </div>
              </div>
            </div>
          </div>

          {/* body */}
          <div className='col input-group input-group-static mb-0 mt-md-0 mt-4'>
            <label>Body</label>
            <Field
              className='form-control'
              id='body'
              placeholder='max length 700 chars'
              name='body'
              as='textarea'
              rows={6}
            />
            <ErrorMessage name='body' component={TextError} />
          </div>

          {/* title */}
          <div className='row my-4'>
            <div className='col-md-6'>
              <div className='input-group input-group-static '>
                <label>Playlist URI</label>
                <Field
                  className='form-control'
                  aria-label='playlistURI'
                  type='text'
                  name='playlistURI'
                />
                <ErrorMessage name='playlistURI' component={TextError} />
              </div>
            </div>
          </div>

          {/* upload picture */}
          <div className='col my-4'>
            <div className='form-group form-file-upload form-file-multiple'>
              <Field>
                {({ field, form, meta }) => {
                  const { setFieldValue } = form;
                  // console.log(form);
                  return (
                    <div className='input-group d-flex flex-column'>
                      <label htmlFor='photo'>Photo</label>
                      <input
                        className='inputFileHidden'
                        id='photo'
                        name='photo'
                        type='file'
                        onChange={(event) => {
                          setFieldValue('photo', event.currentTarget.files[0]);
                        }}
                      />
                    </div>
                  );
                }}
              </Field>
              <ErrorMessage name='photo' component={TextError} />
            </div>
          </div>

          {/* Calendar */}
          <div className='col my-4'>
            <label>Date</label>
            <Field name='date' component={ArticleDate} />
          </div>

          {/* upload */}
          <div className='col row'>
            <div className='col-md-12 text-center'>
              <button
                type='submit'
                className='btn bg-gradient-primary mt-3 mb-0'
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default ArticleForm;
