import React from 'react';
import * as ReactDomClient from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './redux/store';
import App from './components/App';

const container = document.querySelector('#root');
const root = ReactDomClient.createRoot(container);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
