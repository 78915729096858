import React from 'react';

const MCHero = ({ header }) => {
  return (
    <header className='header-2'>
      <div
        className='page-header min-vh-55'
        style={{
          backgroundImage: 'url("./assets/img/hero/backstage.jpg")',
        }}
        loading='lazy'
      >
        <span className='mask bg-gradient-dark opacity-6' />
        <div className='container'>
          <div className='row'>
            <div className='col-lg-7 text-center mx-auto'>
              <h1 className='text-white pt-3 mt-n5'>{header}</h1>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default MCHero;
