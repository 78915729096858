import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from '../components/Utilities/Header';
import BlogMain from '../components/Blog/BlogMain';
import BlogMain2 from '../components/Blog/BlogMain2';
import PageArticle from '../components/Blog/PageArticle';
import { useRellax } from '../hooks/useRellax';
import { heroOpts } from '../constants/heroOpts';

// * BlogLayout functions as a sub layout of RootLayout. It defines the <Outlet /> of the RootLayout in greater specificity

const BlogLayout = () => {
  useRellax();

  const options = heroOpts.get('blog');

  return (
    <>
      <Header img={options.img} title={options.title} text={options.text} />
      <div className='card card-body shadow-xl mx-3 mx-md-4 mt-n6 rellax mobile--p-zero'>
        <Routes>
          <Route index element={<BlogMain />} />
          <Route path='/page-2' element={<BlogMain2 />} />
          <Route path='/post/*' element={<PageArticle />} />
        </Routes>
      </div>
    </>
  );
};

export default BlogLayout;
