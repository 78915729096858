import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from '../components/Utilities/Header';
import Referral from '../components/Utilities/Referral';
import ModalReferral from '../components/Modals/ModalReferral';

import { heroOpts } from '../constants/heroOpts';

// * ReferralLayout functions as a sub layout of RootLayout. It defines the <Outlet /> of the RootLayout for incoming playlist referrals

const ReferralLayout = () => {
  const options = heroOpts.get('home');

  return (
    <>
      <Header
        img={options.img}
        height={100}
        title={options.title}
        text={options.text}
      />
      <Routes>
        <Route index element={<Referral modal={<ModalReferral />} />} />
        <Route path='/*' element={<Referral modal={<ModalReferral />} />} />
      </Routes>
    </>
  );
};

export default ReferralLayout;
