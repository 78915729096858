import React from 'react';
import ReactEcharts from 'echarts-for-react';

const GraphDonut = ({ data }) => {
  if (!data) return;

  const { chartLengths } = data;
  const top10s = chartLengths.top10s.reduce((a, b) => a + b);
  const top20s = chartLengths.top20s.reduce((a, b) => a + b);
  const top40s = chartLengths.top40s.reduce((a, b) => a + b);

  const option = {
    title: {
      text: 'by Size',
      left: 'center',
    },
    tooltip: {
      trigger: 'item',
    },
    // legend: {
    //   orient: 'vertical',
    //   left: 'right',
    // },
    series: [
      {
        name: '',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          { value: top10s, name: 'Top 10' },
          { value: top20s, name: 'Top 20' },
          { value: top40s, name: 'Top 40' },
        ],
      },
    ],
  };

  return <ReactEcharts option={option} />;
};

export default GraphDonut;
