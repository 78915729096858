import React from 'react';
import PlaylistForm from '../Playlists/PlaylistForm';
import PlaylistCard from '../Playlists/PlaylistCard';
import PlaylistTitle from '../Playlists/PlaylistTitle';
import PlaylistDesc from '../Playlists/PlaylistDesc';

const TabPlaylists = () => {
  return (
    <section className='py-7'>
      <div className='container'>
        <div className='row align-items-start'>
          <PlaylistForm
            titleField={<PlaylistTitle />}
            descField={<PlaylistDesc />}
          />
          <PlaylistCard />
        </div>
      </div>
    </section>
  );
};

export default TabPlaylists;
