import React from 'react';
import PlaylistCard from './PlaylistCard';
import PlaylistForm from './PlaylistForm';

const PlaylistMain = () => {
  return (
    <section className='py-7 mobile--p-zero mobile--mt3'>
      <div className='container'>
        <div className='row'>
          <PlaylistForm />
          <PlaylistCard />
        </div>
      </div>
    </section>
  );
};

export default PlaylistMain;
