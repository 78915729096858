import React from 'react';
import { Link } from 'react-router-dom';
import NavLink from './NavLink';
import useAuth from '../../hooks/useAuth';
import { renderNavLinks } from '../../functions/renderNavLinks';
import { navLinkOpts } from '../../constants/navLinkOpts';

const NavDesktop = ({ whiteTxt, whiteBg }) => {
  const user = useAuth();

  return (
    <nav
      className={`navbar fixed-top navbar-expand-lg py-3 ${whiteBg} d-none d-lg-block`}
    >
      <div className='container d-flex'>
        <Link className={`navbar-brand fs-5 ${whiteTxt}`} to='/'>
          Chartify
        </Link>
        <div className='w-100 pt-3 pb-2 py-lg-0  '>
          <ul
            className='navbar-nav navbar-nav-hover ms-auto nav justify-content-end'
            style={{ height: '100%' }}
          >
            {renderNavLinks(NavLink, user, navLinkOpts, whiteTxt)}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavDesktop;
