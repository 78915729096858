import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Article from './Article';
import { useAsyncFetch } from '../../hooks/useAsyncFetch';
import { fetchArticles } from '../../redux/slices/articles';

const PageArticle = () => {
  // * ----- STATE -----
  const { articles } = useSelector((state) => state);
  const { status } = articles;
  const { lead, recent, more } = articles.articles;

  // * ----- HOOKS -----
  const location = useLocation();
  useAsyncFetch(fetchArticles);

  // * ----- RENDER -----
  const renderPage = (lead, recent, more) => {
    if (status !== 'succeeded') {
      return <div></div>;
    } else {
      // * match pathname to article.pathname & return article for render
      const array = [lead, ...recent, ...more];

      const article = array.filter(
        (article) => article.pathname === location.pathname
      )[0];

      return <Article article={article} />;
    }
  };

  return (
    <section className='py-5 mt-5 mobile--p-zero'>
      {renderPage(lead, recent, more)}
    </section>
  );
};

export default PageArticle;
