import React, { useState } from 'react';
import NavDesktop from './NavDesktop';
import NavMobile from './NavMobile';
import { useStyleNavbar } from '../../hooks/useStyleNavbar';

// * NOTE - mobile vs desktop nav is controlled through bootstrap d-display classes. They toggle at the division between lg & xl screen size

const Navbar = () => {
  // * state
  const [transparentHeader, setTransparentHeader] = useState(true);

  // * set setTransparentHeader on scroll
  useStyleNavbar(setTransparentHeader);

  // * toggle navbar classes on scroll (bg color, txt color & box-shadow)
  const whiteBg = transparentHeader ? 'top' : 'bg-white';
  const whiteTxt = transparentHeader ? 'text-white' : '';
  const hamburgerColor = transparentHeader ? 'ham-light' : 'ham-dark';

  return (
    <>
      <NavDesktop whiteTxt={whiteTxt} whiteBg={whiteBg} />
      <NavMobile
        whiteTxt={whiteTxt}
        whiteBg={whiteBg}
        hamburgerColor={hamburgerColor}
      />
    </>
  );
};

export default Navbar;
