import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createBrowserRouter,
  Route,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';
import { fetchUser } from '../redux/slices/user';
import AuthContext from '../context/AuthContext';
import RequireLogin from './Utilities/RequireLogin';

// * ----- LAYOUT -----
// * layouts
import RootLayout from '../layouts/RootLayout';
import ReferralLayout from '../layouts/ReferralLayout';
import BlogLayout from '../layouts/BlogLayout';

// * components
import Home from './Home/Home';
import Dashboard from './Dashboard/Dashboard';
import Admin from './Admin/Admin';
import Playlists from './Playlists/Playlists';
import Support from './Support/Support';
import Privacy from './Privacy/Privacy';
import MailChimpConfirm from './MailChimp/MailChimpConfirm';
import MailChimpThanks from './MailChimp/MailChimpThanks';

const ErrorPage = () => <h1>Error Page</h1>;
const Unauthorized = () => <h1>Unauthorized</h1>;

// * ----- ROUTER INIT -----
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/*' element={<RootLayout />}>
      {/* public routes */}
      <Route index element={<Home />} />
      <Route path='referral/*' element={<ReferralLayout />} />
      <Route path='support' element={<Support />} />
      <Route path='blog/*' element={<BlogLayout />} />
      <Route path='privacy' element={<Privacy />} />
      <Route path='confirm' element={<MailChimpConfirm />} />
      <Route path='thanks' element={<MailChimpThanks />} />
      <Route path='unauthorized' element={<Unauthorized />} />

      {/* login routes v2 */}
      <Route element={<RequireLogin />}>
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='admin' element={<Admin />} />
        <Route path='playlists' element={<Playlists />} />
      </Route>

      {/* catch all */}
      <Route path='*' element={<ErrorPage />} />
    </Route>
  )
);

const App = () => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  console.log(user);
  // * fetch current user on startup
  useEffect(() => {
    dispatch(fetchUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // * dynamic url for /user/:userID comes from the express route handler for /auth/spotify/callback/
  return (
    <AuthContext.Provider value={user}>
      <RouterProvider router={router} />
    </AuthContext.Provider>
  );
};

export default App;
