import React from 'react';
import ArticleForm from './ArticleForm';

const TabArticle = () => {
  return (
    <section>
      <div className='container py-4'>
        <div className='row'>
          <div className='col-lg-8 mx-auto d-flex justify-content-center flex-column'>
            <div className='card d-flex justify-content-center p-4 shadow-lg'>
              <div className='text-center'>
                <h3 className='text-gradient text-primary'>Add Article</h3>
                <p className='mb-4'>
                  Fill in all fields to add a new article to the Chartify
                  Database. Site will update automatically.
                </p>
              </div>
              <div className='card card-plain'>
                <ArticleForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TabArticle;
