import React from 'react';
import { ReactComponent as LocalsLogo } from '../Utilities/svg/l-letter2.svg';

const Contribute = () => {
  const onBtnClick = (wallet) => {
    // Copy the text inside the text field
    navigator.clipboard.writeText(wallet);

    // Alert the copied text
    alert(`Wallet address: ${wallet} copied to clipboard`);
  };

  return (
    <div className='col-lg-4 ms-auto mt-lg-0 mt-4 mb-5 py-4 mobile--m-zero mobile--card-m mobile--p-zero '>
      <div className='card card-pricing'>
        <div className='bg-gradient-warning text-center py-3 position-relative mx-3 mt-n4 border-radius-xl'>
          <div className='z-index-1 position-relative px-5'>
            <h4 className='text-white mb-3'>Contribute</h4>
            <hr className='horizontal dark' />
            <p className='text-white'>Support new features on Chartify</p>
          </div>
        </div>
        <div className='card-body text-center mobile--contribute-p'>
          <ul className='list-unstyled max-width-250 mx-auto'>
            <li className=''>
              <a
                href='https://paypal.me/koribrus?country.x=CA&locale.x=en_US'
                className='btn btn--custom btn--paypal w-50 mb-0'
              >
                <i className='fa-brands fa-paypal pe-2'></i>PayPal
              </a>
              <hr className='horizontal dark my-4' />
            </li>
            <li>
              <a
                href='https://chartify.locals.com/'
                className='btn btn--custom btn--patreon w-50 mb-0'
              >
                {/* <i className='fa-brands fa-patreon pe-2'></i> */}
                <div className='d-flex justify-content-center'>
                  <LocalsLogo />
                  <span className='ps-2'>Locals</span>
                </div>
              </a>
              <hr className='horizontal dark my-4 ' />
            </li>
            <li className='d-flex flex-column align-items-center'>
              <img
                className='qr-code'
                src='../assets/img/bitcoin-qr.png'
                alt='bitcoin qr code'
              />
              <div
                className='btn btn--custom btn--btc w-50 mt-3 mb-0'
                onClick={() =>
                  onBtnClick('bc1qk4r73v5z6274k2vxjs8ycjl57u688f3fu20220')
                }
              >
                <i className='fa-solid fa-bitcoin-sign pe-2'></i>Bitcoin
              </div>
              <hr className='horizontal dark w-100 my-4' />
            </li>
            <li className='d-flex flex-column align-items-center'>
              <img
                className='qr-code'
                src='../assets/img/ethereum-qr.png'
                alt='bitcoin qr code'
              />
              <div
                className='btn btn--custom btn--eth w-50 mt-3 mb-0'
                onClick={() =>
                  onBtnClick('0x9F7D9eEBe6034Dc7C4C4376D135FcFca126FCb84')
                }
              >
                <i className='fa-brands fa-ethereum pe-2'></i>Ethereum
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Contribute;
