import React from 'react';
import CarouselPosts from './CarouselPosts';
import ControlArrows from './ControlArrows';
import ControlButtons from './ControlButtons';

const Carousel = ({ articles }) => {
  return (
    <section className='position-relative mb-5 mobile--carousel-m'>
      <div id='carousel-body' className='carousel slide carousel-team'>
        <CarouselPosts articles={articles} />
        <ControlArrows />
      </div>
      <ControlButtons articles={articles} />
    </section>
  );
};

export default Carousel;
