import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowModal } from '../../redux/slices/chartify';
import { setReferral } from '../../redux/slices/chartify';

// This modal appears on the /playlists page after being redirected from a past Hot 100 article. Chartify's Hot 100 playlists are always current. The redirect from the article prompts a login and autofills the create playlist form with the correct data from the article's chart. ModalTop10 provides instructions.

const ModalTop10 = () => {
  const { selectedDate } = useSelector((state) => state.spotify);
  const date = new Date(selectedDate).toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  const dispatch = useDispatch();

  // * close modal
  const handleClick = () => {
    dispatch(setShowModal(false));
    dispatch(setReferral(false));
  };

  return (
    <div className='modal--overlay' onClick={handleClick}>
      <div
        className='card modal--redirect mobile--modal'
        style={{ width: '40rem' }}
      >
        <div className='card-body'>
          <h4 className='card-title'>Create Playlist</h4>
          <h6 className='card-subtitle mb-2 text-muted pb-3'>{`Billboard Top 10\u00A0\u00A0|\u00A0\u00A0 ${date}`}</h6>
          <hr className='horizontal dark' />
          <p className='card-text pb-3'>
            {`Want the article's chart? Just confirm the chart size and hit Submit to get started.`}
          </p>
          <button
            type='button'
            className='btn btn-primary'
            onClick={handleClick}
          >
            Dismiss
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalTop10;
