import React from 'react';
import CarouselPost from './CarouselPost';
import { renderPosts } from '../../functions/renderPosts';

const CarouselPosts = ({ articles }) => {
  return (
    <div className='carousel-inner'>
      {renderPosts(articles, CarouselPost, 'active')}
    </div>
  );
};

export default CarouselPosts;
