import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Stack, TextField } from '@mui/material';

const ArticleDate = ({ field, form, ...other }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={4} sx={{ width: '250px' }}>
        <DatePicker
          label='Choose a date'
          name={field.name}
          value={field.value}
          dateFormat='MM/dd/yyyy'
          allowKeyboardControl={false}
          onChange={(date) => form.setFieldValue(field.name, date, true)}
          renderInput={(params) => <TextField {...params} />}
          {...other}
        />
      </Stack>
    </LocalizationProvider>
  );
};

export default ArticleDate;
