import React from 'react';
import DashboardText from './DashboardText';
import DashboardCard from './DashboardCard';

const DashboardIntro = ({ user, data }) => {
  return (
    <section>
      <div className='container mobile--px1'>
        <div className='row'>
          <DashboardText user={user} data={data} />
          <DashboardCard />
        </div>
      </div>
    </section>
  );
};

export default DashboardIntro;
