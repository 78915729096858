import React from 'react';
import { useDispatch } from 'react-redux';
import { setPlaylistDesc } from '../../redux/slices/spotify';

const PlaylistDesc = () => {
  const dispatch = useDispatch();

  return (
    <div style={{ width: '100%' }}>
      <h6 className='mt-4'>Playlist Description</h6>
      <section className=' '>
        <div className=''>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='input-group input-group-outline'>
                <label className='form-label'></label>
                <textarea
                  className='form-control'
                  type='text'
                  onChange={(e) => {
                    dispatch(setPlaylistDesc(e.target.value));
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PlaylistDesc;
