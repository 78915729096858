import React from 'react';
import { Link } from 'react-router-dom';
import NavLinkMbl from './NavLinkMbl';
import useAuth from '../../hooks/useAuth';
import { useSideNav } from '../../hooks/useSideNav';
import { useCloseMenu } from '../../hooks/useCloseMenu';
import { renderNavLinks } from '../../functions/renderNavLinks';
import { navLinkOpts } from '../../constants/navLinkOpts';

const NavMobile = ({ whiteTxt, whiteBg, hamburgerColor }) => {
  // * ----- HOOKS -----
  // * context
  const user = useAuth();
  // * animate side menu
  useSideNav();
  // * close menu on navigation
  useCloseMenu();

  return (
    <nav
      className={`navbar fixed-top navbar-expand-lg ${whiteBg} d-lg-none p-0 slide-nav`}
    >
      <div className='container side-nav py-3 mobile--px-custom'>
        <Link className={`navbar-brand fs-5 ${whiteTxt}`} to='/'>
          Chartify
        </Link>
        <input type='checkbox' className='toggler' autoComplete='off' />
        <div className='hamburger'>
          <div className={`${hamburgerColor}`}></div>
        </div>
        <div className='menu-wrap d-flex'>
          <div className='menu bg-white d-flex px-3 py-5 w-100' title='sidenav'>
            <div className='w-100'>
              <div className='menu-hamburger w-100'>
                <ul
                  className='navbar-nav navbar-nav-hover ms-auto nav mt-3'
                  style={{ height: '100%' }}
                >
                  {renderNavLinks(NavLinkMbl, user, navLinkOpts)}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavMobile;
