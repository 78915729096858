import React from 'react';
import { useDispatch } from 'react-redux';
import { setShowModal } from '../../redux/slices/chartify';
import { setReferral } from '../../redux/slices/chartify';

// * this modal appears after being redirected via the ModalReferrer.js modal via a social media referral link.

const ModalReferral2 = () => {
  const dispatch = useDispatch();

  // * close modal
  const handleClick = () => {
    dispatch(setShowModal(false));
    dispatch(setReferral(false));
  };

  return (
    <div className='modal--overlay max-vw-95' onClick={handleClick}>
      <div
        className='card modal--redirect mobile--modal'
        style={{ width: '40rem' }}
      >
        <div className='card-body'>
          <h4 className='card-title text-center pb-2'>Almost There!</h4>
          <hr className='horizontal dark' />
          <p className='card-text text-center py-3'>
            We've filled in all the data you need. Hit Submit on the form for a
            preview. If it looks good, you can add the playlist to your Spotify
            account.
          </p>
          <p className='card-text text-center text-info text-bold pb-3'>
            Happy listening!
          </p>
          <div className='d-flex justify-content-center'>
            <button
              type='button'
              className='btn btn-primary'
              onClick={handleClick}
            >
              Dismiss
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalReferral2;
