import React from 'react';
import { useSelector } from 'react-redux';
import PlaylistCardBack from './PlaylistCardBack';
import PlaylistCardFront from './PlaylistCardFront';
import { useRotateCard } from '../../hooks/useRotateCard';

const PlaylistCard = () => {
  // * state
  const { spotify } = useSelector((state) => state);
  const { playlist, playlistURI } = spotify;

  // * hooks
  useRotateCard(true, playlist);
  useRotateCard(false, playlistURI);

  return (
    <div className='col-lg-7 ms-auto mt-lg-0 mt-4 custom-card-container--rotate'>
      <div className='ms-auto mt-lg-0 mt-4 custom-card--rotate flip playlist-card--container mobile--mb3'>
        {/* <PlaylistCardBack spotify={spotify} /> */}
        <PlaylistCardBack />
        <PlaylistCardFront spotify={spotify} />
      </div>
    </div>
  );
};

export default PlaylistCard;
