import React, { useEffect, useState } from 'react';

const Countdown = () => {
  // * ----- STATE -----
  // * countdown for render display
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  // * ----- COUNT INTERVAL -----
  // Counter is an self resetting loop via 'const increment'. Once we reach Saturday daysRemaining === 0 & increment is set back to 7. If it's not Saturday daysRemaining is the current clock point.
  useEffect(() => {
    setInterval(function () {
      // * set current date
      const now = new Date();
      // * set countdown target day (tuesday = 2, saturday = 6)
      const targetDay = 6;

      // * calculate time until countdownDate (milliseconds until next Saturday)
      const daysRemaining = (7 + targetDay - now.getDay()) % 7;
      const increment = daysRemaining === 0 ? 7 : daysRemaining;
      const msInDay = 24 * 60 * 60 * 1000;
      const interval = increment * msInDay;

      // * calculate target date
      const countdownDate = new Date(now.getTime() + interval);
      countdownDate.setHours(0, 0, 0, 0);

      // * calculate interval between now and target
      const countInterval = countdownDate.getTime() - now.getTime();

      // * update Chartify Billboard lists at time === 0
      // ! to come

      // * time calculations
      let days = Math.floor(countInterval / (1000 * 60 * 60 * 24));
      let hours = Math.floor(
        (countInterval % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor(
        (countInterval % (1000 * 60 * 60)) / (1000 * 60)
      );
      let seconds = Math.floor((countInterval % (1000 * 60)) / 1000);
      const updateTime = {
        days,
        hours,
        minutes,
        seconds,
      };

      setCountdown(updateTime);
    }, 1000);
  }, []);

  return (
    <div className='row d-flex justify-content-start my-5 mobile--card-m'>
      <div className='col-12 mx-auto'>
        <div className='card card-pricing'>
          <div className='chartify-black-gradient text-center py-3 position-relative mx-3 mt-n4 border-radius-xl'>
            <div className='z-index-1 position-relative px-5'>
              <h4 className='text-white mb-3'>Next Weekly Chart</h4>
              <hr className='horizontal dark bg-light' />
            </div>
          </div>
          <div className='card-body text-center'>
            <div className='row justify-content-center text-center pt-1 countdown-timer'>
              <div className='col-lg-3 col-3'>
                <h5 className='text-primary mb-0'>{countdown.days}</h5>
                <small>Days</small>
              </div>
              <div className='col-lg-3 col-3'>
                <h5 className='text-primary mb-0'>{countdown.hours}</h5>
                <small>Hours</small>
              </div>
              <div className='col-lg-3 col-3'>
                <h5 className='text-primary mb-0'>{countdown.minutes}</h5>
                <small>Mins</small>
              </div>
              <div className='col-lg-3 col-3'>
                <h5 className='text-primary mb-0'>{countdown.seconds}</h5>
                <small>Secs</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Countdown;
