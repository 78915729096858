import React from 'react';

const SubscribeFtr = () => {
  return (
    <div className='position-relative'>
      <div className='container'>
        <form
          action='https://media.us18.list-manage.com/subscribe/post'
          method='post'
        >
          <div>
            <input
              type='hidden'
              name='u'
              defaultValue='e62f555f23a5e4e115eb2f6d2'
            />
            <input type='hidden' name='id' defaultValue='53a9c86455' />
          </div>
          <div className='card bg-gradient-info z-index-3'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-md-8 mx-auto text-center my-auto mobile--zero-p'>
                  <h3 className='text-white'>Stay up to date</h3>
                  <p className='text-white opacity-8 px-5 mobile--px2'>
                    Get notified about the latest Chartify playlists and newest
                    features.
                  </p>
                  <div className='row'>
                    <div className='col-lg-5 col-md-7 col-sm-5 col-xs-3 ms-auto'>
                      <div className='footer--custom-margin'>
                        <div className='input-group input-group-outline'>
                          <label className='form-label'>Email Here...</label>
                          <input
                            type='email'
                            className='form-control text-white'
                            autoCapitalize='off'
                            autoCorrect='off'
                            name='MERGE0'
                            id='MERGE0'
                            size={25}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-5 col-sm-4 ps-0 me-auto text-start footer--custom-spacing'>
                      <div className='footer--custom-margin'>
                        <div className='footer--custom-btn-spacing'>
                          <div
                            className=''
                            style={{ position: 'absolute', left: '-5000px' }}
                            aria-hidden='true'
                          >
                            <input
                              type='text'
                              name='b_e62f555f23a5e4e115eb2f6d2_53a9c86455'
                              tabIndex={-1}
                              value=''
                              readOnly
                            />
                          </div>
                          <input
                            type='submit'
                            className='btn bg-gradient-danger text-white w-100 position-relative z-index-2 footer-custom-btn'
                            name='submit'
                            value='Subscribe'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SubscribeFtr;
