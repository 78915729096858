import React from 'react';
import { useSelector } from 'react-redux';
import PostHorizontal from './PostHorizontal';
import SupportCard from '../Cards/SupportCard';
import Countdown from '../Cards/Countdown';
import Top5Card from '../Cards/Top5Card';
import { renderPosts } from '../../functions/renderPosts';
import { useAsyncFetch } from '../../hooks/useAsyncFetch';
import { fetchArticles } from '../../redux/slices/articles';

const BlogMain2 = () => {
  // * ----- STATE -----
  const { more } = useSelector((state) => state.articles.articles);

  // * ----- HOOKS -----
  useAsyncFetch(fetchArticles);

  return (
    <section className='my-5 mobile--mb0'>
      <div className='container px-5 mobile--px2'>
        <div className='row align-items-start'>
          <div className='col-lg-8 mobile--card-m'>
            <div className='row'>
              <div className='col-lg-6'>
                <h2 className=''>More posts...</h2>
              </div>
            </div>
            <div className='row'>
              <div className='ms-auto me-auto'>
                {more ? renderPosts(more, PostHorizontal) : ''}
              </div>
            </div>
          </div>
          <div className='col-lg-4 py-3 mobile--p-zero'>
            <SupportCard />
            <Top5Card />
            <Countdown />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogMain2;
