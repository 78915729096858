import React, { useEffect } from 'react';

const PrivacyMain = () => {
  useEffect(() => {
    var s = document.createElement('script');
    let tag = document.getElementsByTagName('script')[0];

    s.src = 'https://cdn.iubenda.com/iubenda.js';

    tag.parentNode.insertBefore(s, tag);
  }, []);

  return (
    <div className='col-9 mx-auto pb-5'>
      <div className='card shadow-lg'>
        <div className='card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent'>
          <div className='bg-gradient-dark shadow-dark border-radius-lg p-4'>
            <h3 className='text-white mb-0'>Privacy &amp; Policy</h3>
            <p className='text-white opacity-8 mb-0'>
              Last modified: Mar 24 2023
            </p>
          </div>
        </div>
        <div className='card-body p-5 pt-0'>
          <iframe
            id='inlineFrameExample'
            title='Chartify Privacy Policy'
            width='100%'
            height='4407'
            scrolling='no'
            src='https://www.iubenda.com/privacy-policy/22100136/legal'
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default PrivacyMain;
