import { useEffect } from 'react';

// * hook to toggle navbar transparency in desktop mode

export const useStyleNavbar = (setHeaderState) => {
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 350) {
        setHeaderState(false);
      } else {
        setHeaderState(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [setHeaderState]);
};
