import { useEffect } from 'react';

// * hook for closing slide menu after nav click

export const useCloseMenu = () => {
  useEffect(() => {
    const navbar = document.querySelector('.slide-nav');
    const navBtns = document.querySelectorAll('.nav-item-mobile');
    const toggler = document.querySelector('.toggler');

    const handleNavClick = () => {
      navbar.style.transform = 'translateX(0)';
      toggler.checked = false;
    };

    navBtns.forEach((btn) =>
      btn.addEventListener('click', () => handleNavClick())
    );

    return () =>
      navBtns.forEach((btn) =>
        btn.removeEventListener('click', () => handleNavClick())
      );
  });
};
