import React from 'react';
import { useNavigate } from 'react-router-dom';

const DashboardCard = () => {
  const navigate = useNavigate();

  return (
    <div className='col-md-5 me-auto my-auto ms-md-5 mobile--card-m'>
      <div className='card mt-md-n12 bg-gradient-primary'>
        <div className='card-body py-5 text-center'>
          <p className='text-white text-uppercase'>Create Playlists</p>
          <h3 className='text-white up mb-0'>Add to your Dashboard</h3>
          <p className='text-white opacity-8 mx-5 my-4'>
            The more you listen, the more info you'll see about the music you
            follow.
          </p>
          <button
            type='button'
            className='btn btn-white btn-lg mt-3'
            onClick={() => navigate('/playlists')}
          >
            New Playlist
          </button>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
