import React from 'react';
import Header from '../Utilities/Header';
import Page from '../Utilities/Page';
import SupportMain from './SupportMain';

import { heroOpts } from '../../constants/heroOpts';

const Support = () => {
  const options = heroOpts.get('support');

  return (
    <Page
      hero={
        <Header img={options.img} title={options.title} text={options.text} />
      }
      main={<SupportMain />}
    />
  );
};

export default Support;
