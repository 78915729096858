import React from 'react';
import { Link } from 'react-router-dom';

const NavLinkMbl = ({ to, icon, text }) => {
  return (
    <li className='nav-item d-flex py-3 nav-hr nav-item-mobile'>
      <Link
        className='nav-link active d-flex my-auto'
        aria-current='page'
        to={to}
      >
        <i
          className='material-icons opacity-6 me-1 text-md my-auto'
          aria-hidden='true'
        >
          {icon}
        </i>
        {text}
      </Link>
    </li>
  );
};

export default NavLinkMbl;
