import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useRellax } from '../../hooks/useRellax';

// showModal state && modal param (optional) control availability of modal on any given page. A custom modal can be passed to any particular page. App doesn't use createPortal. Page component represents the top level of any Chartify Page.

const Page = ({ hero, main, modal = null }) => {
  const { showModal } = useSelector((state) => state.chartify);
  const { pathname } = useLocation();

  useRellax();

  // * remove container classes for privacy page
  const classes =
    pathname === '/privacy'
      ? ''
      : 'card card-body shadow-xl mx-3 mx-md-4 mt-n6';

  const hidden = main === 'none' ? 'referral-layout' : '';

  return (
    <>
      {hero}
      <div className={`${classes} rellax ${hidden}`}>{main}</div>
      {showModal && modal}
    </>
  );
};

export default Page;
