import React from 'react';
import { useSelector } from 'react-redux';
import Carousel from './Carousel';
import Top5Card from '../Cards/Top5Card';
import SupportCard from '../Cards/SupportCard';
import Countdown from '../Cards/Countdown';
import CreatePlaylist from '../Cards/CreatePlaylist';
import { useAsyncFetch } from '../../hooks/useAsyncFetch';
import { fetchArticles } from '../../redux/slices/articles';

const Main = () => {
  // * ----- STATE -----
  const { articles } = useSelector((state) => state);
  const { status } = articles;
  const { lead, recent } = articles.articles;

  // * ----- HOOKS -----
  useAsyncFetch(fetchArticles);

  // * ----- RENDER -----
  // * select articles
  const mergeArticles = (lead, recent) => {
    if (recent) {
      return [lead, ...recent];
    }
  };

  // * helper
  const renderCarousel = () => {
    if (status !== 'succeeded') {
      return <div></div>;
    } else {
      return <Carousel articles={mergeArticles(lead, recent)} />;
    }
  };

  return (
    <div className='my-5 pt-3 mobile--m-zero mobile--p-zero'>
      <div className='container mobile--p-zero'>
        <div className='row align-items-start'>
          {/* carousel */}
          <div className='col-lg-8 ms-auto me-auto mt-lg-0'>
            {renderCarousel()}
          </div>

          {/* chart content & info */}
          <div className='col-lg-4 ms-auto ps-4 d-flex flex-column mobile--px1'>
            <Top5Card />
            <SupportCard />
            <Countdown />
          </div>
        </div>
      </div>
      <div className='my-5 mobile--card-m'>
        <CreatePlaylist text='Get started' />
      </div>
    </div>
  );
};

export default Main;
