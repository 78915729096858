import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../functions/formatDate';
import { categoryColor } from '../../functions/categoryColor';

const PostVertical = ({ article }) => {
  const navigate = useNavigate();
  const handleClick = (pathname) => navigate(pathname);

  return (
    <div className='col-lg-4 post-vertical'>
      <div className='card mb-5'>
        <div className='card-header p-0 mx-3 mt-n4 position-relative z-index-1'>
          <div className='d-block blur-shadow-image'>
            <img
              src={article.photoURL}
              className='post-photo-ver img-fluid border-radius-md'
              alt='artist'
              loading='lazy'
            />
          </div>
        </div>
        <div className='card-body'>
          <span
            className={`text-gradient text-uppercase text-xs font-weight-bold ${categoryColor(
              article.category
            )}`}
          >
            {article.category}
          </span>
          <Link
            to={article.pathname}
            className='card-title mt-3 h5 d-block text-darker'
          >
            {article.title}
          </Link>
          <p className='card-description mb-4'>
            {article.body.slice(0, 100)}...{' '}
            <span
              href='#'
              className='text-primary article-link'
              onClick={() => handleClick(article.pathname)}
            >
              {' '}
              Read More{' '}
            </span>
          </p>
          <div className='author align-items-center'>
            <img
              src='../../assets/img/graphics/chartify.png'
              alt='...'
              className='avatar shadow'
              loading='lazy'
            />
            <div className='name ps-2'>
              <span>{article.author}</span>
              <div className='stats'>
                <small>{`Posted on ${formatDate(article.date)}`}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostVertical;
