import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// * ----- THUNKS -----
// * api urls
const USER_URL = '/api/current_user';
const DASHBOARD_URL = '/api/dashboard';

// * Thunk for user login status
export const fetchUser = createAsyncThunk('fetchUser', async () => {
  try {
    const response = await axios.get(USER_URL);
    return response.data;
  } catch (error) {
    return error.message;
  }
});

// * fetch user's saved charts
export const fetchUserData = createAsyncThunk('saveChart', async () => {
  try {
    const response = await axios.get(DASHBOARD_URL);
    return response.data;
  } catch (error) {
    return error.message;
  }
});

// * ----- SLICE -----
const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    charts: null,
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload || false;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.user = action.payload || false;
      })
      .addCase(fetchUserData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.charts = action.payload;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

// * ----- ACTIONS & REDUCERS -----
export default userSlice.reducer;
