import React from 'react';

const UpdateCardFront = ({ spotify }) => {
  const renderFrontCard = (playlistURI) => {
    switch (playlistURI) {
      case null:
        return (
          <div className='card bg-gradient-secondary custom-card--front custom-card--playlist'>
            <div className='card-body'>
              <h6 className='card-category text-primary'>Chartify</h6>
              <a href='#pablo'>
                <h3 className='card-title text-info'>
                  Billboard Chart Updater
                </h3>
              </a>

              <p className='card-description text-white'>
                Click submit to preview the current Top 40
              </p>
            </div>
          </div>
        );
      default:
        return (
          <div className='card custom-card--front'>
            <iframe
              title='user playlist'
              style={{ borderRadius: '12px' }}
              src={`https://open.spotify.com/embed/playlist/${playlistURI}?utm_source=generator`}
              width='100%'
              height='100%'
              allowFullScreen=''
              allow='autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture'
              loading='lazy'
            ></iframe>
          </div>
        );
    }
  };

  return renderFrontCard(spotify.playlistURI);
};

export default UpdateCardFront;
