import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

/* ----- OVERVIEW -----
Chartify slice holds various states that impact UI and component rendering.
1) top5: holds the top 5 chart songs from rendering in Top5Card.js
2) playlistRedirect: boolean value indicating if an instruction modal should render when 
a user is redirected from a Hot 100 blog post to recreate a previous week's chart.
*/

// * ----- THUNKS -----
// * api urls
const URL = '/api/top5';

export const fetchTop5 = createAsyncThunk(
  'fetchTop5',
  async (playlistURI, thunkAPI) => {
    try {
      // thunkAPI.dispatch(setStatus('idle'));
      const response = await axios.get(URL);
      return response.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const updateTop5 = createAsyncThunk(
  'updateTop5',
  async (top5, thunkAPI) => {
    try {
      thunkAPI.dispatch(setStatus('idle'));
      const response = await axios.post(URL, top5);
      return response.data;
    } catch (error) {
      return error.message;
    }
  }
);

const chartifySlice = createSlice({
  name: 'chartify',
  initialState: {
    top5: null,
    showModal: false,
    referral: false,
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
    setReferral: (state, action) => {
      state.referral = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      // * FETCH top 5 tracks of the week
      .addCase(fetchTop5.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTop5.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.top5 = action.payload;
      })
      .addCase(fetchTop5.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // * UPDATE top 5 tracks of the week
      .addCase(updateTop5.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateTop5.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.top5 = action.payload;
      })
      .addCase(updateTop5.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { setStatus, setShowModal, setReferral } = chartifySlice.actions;
export default chartifySlice.reducer;
