import React from 'react';
import Page from '../Utilities/Page';
import MCHero from './MCHero';
import MCMain from './MCMain';
import { mailchimpPage } from '../../constants/mailchimpPage';

const MailChimpThanks = () => {
  const options = mailchimpPage.get('thanks');

  return (
    <Page
      hero={<MCHero header={options.header} />}
      main={
        <MCMain
          title={options.title}
          p1={options.p1}
          p2={options.p2}
          img={options.img}
        />
      }
    />
  );
};

export default MailChimpThanks;
