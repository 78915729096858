import { useEffect } from 'react';

// * hook for rotating card based on state

export const useRotateCard = (flip, state) => {
  useEffect(() => {
    if (flip) {
      const rotatingCard = document.querySelector('.custom-card--rotate');
      rotatingCard.classList.add('flip');

      return;
    }

    if (!flip) {
      const rotatingCard = document.querySelector('.custom-card--rotate');
      rotatingCard.classList.remove('flip');

      return;
    }
  }, [flip, state]);
};
