import React from 'react';
import { useSelector } from 'react-redux';
import Hot100Btns from '../Buttons/Hot100Btns';
import { useAsyncFetch } from '../../hooks/useAsyncFetch';
import { fetchTop5 } from '../../redux/slices/chartify';

const Top5Card = () => {
  // * ----- STATE -----
  const { top5, status } = useSelector((state) => state.chartify);

  // * ----- HOOKS -----
  useAsyncFetch(fetchTop5);

  // * ----- HELPERS -----
  const onButtonClick = () => {
    const card = document.querySelector('.billboard-chart');
    card.classList.toggle('flip');
  };

  const renderCard = (songs, handler) => {
    if (status !== 'succeeded') {
      return <div></div>;
    } else {
      const songRows = songs.map((song, index) => {
        return (
          <tr className='chart-row' key={index}>
            <th className='chart-rank' scope='row'>
              <div className='d-flex flex-column justify-content-center text-info chart-num'>
                {song.rank}
              </div>
            </th>
            <td className='chart-image'>
              <img className='chart-jpg' src={song.imageURL} alt={song.title} />
            </td>
            <td className='d-flex flex-column chart-track'>
              <h6 className='text-gradient text-info text-uppercase text-sm font-weight-bold chart-artist'>
                {song.artist}
              </h6>
              <h6 className='text-gradient text-primary text-uppercase text-sm fw-light chart-song'>
                {song.title}
              </h6>
            </td>
          </tr>
        );
      });

      return (
        <div className='row d-flex justify-content-start my-5 mobile--card-m'>
          <div className='col-12 mx-auto'>
            <div className='rotating-card-container manual-flip custom-card-container--rotate'>
              <div className='card card-rotate custom-card--rotate billboard-chart'>
                {/* FRONT */}
                <div className='front custom-card--front'>
                  <div className='card-body custom-card--rotate-zindex chart-background bg-primary'>
                    {/* TEST CHART CARD */}
                    <div className='row d-flex flex-column justify-content-center align-items-center px-2 '>
                      <div className='card chart-card '>
                        <div className='card-body chart-body'>
                          <h4 className='card-title p-2 chart-title bg-info'>
                            Current Singles Chart
                          </h4>
                          <table className='table table-bordered chart-table'>
                            <tbody className='chart-table-body'>
                              {songRows}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className='stats text-center mt-4'>
                        <button
                          type='button'
                          name='button'
                          className='btn btn-round btn-info btn-rotate m-0'
                          onClick={handler}
                        >
                          <i className='material-icons'>refresh</i> Playlists...
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* BACK */}
                <div className='back custom-card--back '>
                  <div className='card-body custom-card--rotate-zindex chart-background'>
                    <br />
                    <h5 className='card-title'>Hot 100 Playlists</h5>
                    <p className='card-description text-white'>
                      Follow Chartify's playlists for today's Top 10, 20 or 40
                      songs. Updated every week!
                    </p>
                    <div className='container mt-3 px-6'>
                      <div className='stats text-center d-flex flex-column align-items-center'>
                        <Hot100Btns />
                      </div>

                      <button
                        type='button'
                        name='button'
                        className='btn btn-link btn-round btn-rotate text-info'
                        onClick={handler}
                      >
                        <i className='material-icons text-white'>refresh</i>{' '}
                        <span className='text-white'>Back...</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return renderCard(top5, onButtonClick);
};

export default Top5Card;
