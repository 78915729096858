import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Page from '../Utilities/Page';
import Header from '../Utilities/Header';
import DashboardMain from './DashboardMain';
import { useRedirect } from '../../hooks/useRedirect';
import { heroOpts } from '../../constants/heroOpts';

const Dashboard = () => {
  const { user } = useSelector((state) => state.user);
  // * useRedirect manages sessionStorage and guides redirects from successful login to /playlists. Used on 'create playlist' button of articles about past Hot 100 charts && referral redirects from social media posts
  useRedirect();

  // ! test method for doing a full reload if token expires on protected route.
  useEffect(() => {
    if (user === false) window.location.reload(true);
  }, [user]);

  const options = heroOpts.get('dashboard');

  return (
    <Page
      hero={
        <Header img={options.img} title={options.title} text={options.text} />
      }
      main={<DashboardMain />}
    />
  );
};

export default Dashboard;
