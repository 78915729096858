import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../functions/formatDate';

const CarouselPost = ({ article, classes }) => {
  const navigate = useNavigate();
  const handleClick = (pathname) => navigate(pathname);

  return (
    <div className={`carousel-item ${classes}`}>
      <div className='container'>
        <div className='row'>
          <div className='col-md-8'>
            <div className='p-3'>
              <img
                className='w-100 border-radius-xl max-height-600 fadeIn2 fadeInBottom'
                src={article.photoURL}
                alt='First slide'
                loading='lazy'
              />
            </div>
          </div>
          <div className='col-md-4 me-lg-auto position-relative py-3'>
            <h6 className='opacity-7 text-uppercase font-weight-bolder text-sm fadeIn4 fadeInBottom text-primary text-gradient'>
              {formatDate(article.date)}
            </h6>
            <Link to={article.pathname}>
              <h3 className='text-dark font-weight-bolder fadeIn2 fadeInBottom'>
                {article.title}
              </h3>
            </Link>
            <p className='my-4 text-dark fadeIn2 fadeInBottom article-body'>
              {article.body.substring(0, article.body.indexOf('\n'))}
            </p>
            <span
              className='text-primary article-link'
              onClick={() => handleClick(article.pathname)}
            >
              Read More{' '}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselPost;
