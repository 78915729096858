import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

// * hook for use with any button that navigates user to /playlists (CreatePlaylist.js component or GET STARTED button on /home). This is a protected route, so login status must be checked. onClick the button passes clickState === true to useRedirectToPlaylists. The hook will check for login status, login if necessary, and redirect user to /playlists route.

export const useRedirectToPlaylists = (clicked) => {
  const user = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (clicked === false) return;

    if (user) {
      const route = pathname === '/' ? 'playlists' : '/playlists';
      navigate(route);
    } else {
      sessionStorage.setItem('redirect', 'true');
      window.location.href = '/auth/spotify';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clicked]);
};
