import React from 'react';
import Header from '../Utilities/Header';
import Page from '../Utilities/Page';
import PrivacyMain from './PrivacyMain';
import { heroOpts } from '../../constants/heroOpts';

const Privacy = () => {
  const options = heroOpts.get('privacy');

  return (
    <Page
      hero={
        <Header
          img={options.img}
          height={35}
          title={options.title}
          text={options.text}
        />
      }
      main={<PrivacyMain />}
    />
  );
};

export default Privacy;
