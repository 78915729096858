// * NOTES - conditional return. IF user is logged in direct them to the route requests. ELSE send them to the home page. The 'state' and 'replace' attributes allow back navigation to the page users were at prior to forced redirect.

import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const RequireLogin = () => {
  const user = useAuth();
  const location = useLocation();

  return user ? (
    <Outlet />
  ) : user === null ? (
    <div></div>
  ) : (
    <Navigate to='/' state={{ from: location }} replace />
  );
  // return user ? (
  //   <Outlet />
  // ) : (
  //   <Navigate to='/' state={{ from: location }} replace />
  // );
};

export default RequireLogin;
