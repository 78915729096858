export const heroOpts = new Map([
  [
    'home',
    {
      img: 'girl-concert-crowd.jpg',
      title: 'Chartify',
      text: 'Create Spotify playlists of top hits from any week in history',
    },
  ],
  [
    'admin',
    {
      img: 'concert-stage-guitar-bw.jpg',
      title: 'Administration',
      text: 'Credentials required',
    },
  ],
  [
    'dashboard',
    {
      img: 'dj-mixing-outdoor.jpg',
      title: 'Dashboard',
      text: 'Your Chartify snapshot',
    },
  ],
  [
    'playlists',
    {
      img: 'studio-microphone.jpg',
      title: 'Playlists',
      text: 'Make a Spotify playlist from any Hot 100 chart',
    },
  ],
  [
    'blog',
    {
      img: 'acoustic-guitar-concert.jpg',
      title: 'Latest News',
      text: `Get our latest playlists and find out what's next for
      Chartify`,
    },
  ],
  [
    'support',
    {
      img: 'girl-concert.jpg',
      title: 'Support Us',
      text: `Your support drives Chartify's future. There's more to
      come!`,
    },
  ],
  [
    'privacy',
    {
      img: 'privacy.jpg',
      title: 'Legal',
      text: ``,
    },
  ],
]);
