import React from 'react';

const DashboardText = ({ user, data }) => {
  const renderComponent = (data) => {
    switch (data) {
      case null:
        return (
          <div className='col-md-5 ms-auto mobile--card-m'>
            <h2 className='mb-4'>{`Hello ${user}!`}</h2>
            <p className='mb-3'>You haven't created any playlists yet. </p>
            <p>Click the "New Playlist" button to get started!</p>
          </div>
        );
      default:
        return (
          <div className='col-md-5 ms-auto mobile--card-m'>
            <h2 className='mb-4'>{`Hello ${user}!`}</h2>
            <p className='mb-3'>
              Wondering what you've been listening to on Chartify? Below is a
              breakdown of all your playlists to date. Check out your stats by:{' '}
            </p>
            <ul className='m-lg-2 m-auto'>
              <li className='mb-2'>
                <p>summary</p>
              </li>
              <li className='mb-2'>
                <p>decade</p>
              </li>
              <li className='mb-2'>
                <p>chart size</p>
              </li>
              {/* <li className='mb-3'>artists</li> */}
            </ul>
            <p className='mobile--m-zero'>
              Explore the decades you've missed. Go deeper into longer
              playlists. Get inspiration from the artists you keep coming back
              to.
            </p>
          </div>
        );
    }
  };
  return renderComponent(data);
};

export default DashboardText;
