import React from 'react';
import PostHorizontal from './PostHorizontal';
import { renderPosts } from '../../functions/renderPosts';

const MorePosts = ({ articles }) => {
  return (
    <section className='py-5 mobile--p-zero mobile--card-m'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6'>
            <h3 className='mt-4 mobile--m-zero'>More posts</h3>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-10 ms-auto me-auto'>
            {renderPosts(articles, PostHorizontal)}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MorePosts;
