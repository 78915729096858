import React, { useState } from 'react';
import { useRedirectToPlaylists } from '../../hooks/useRedirectToPlaylists';

const Header = ({ img, height, title, text, home }) => {
  const [clicked, setClicked] = useState(false);
  const heroHeight = height ? height : 65;

  useRedirectToPlaylists(clicked);

  const handleClick = () => {
    setClicked(true);
  };

  const renderHeader = (home) => {
    switch (home) {
      case true:
        return (
          <header className='header-2'>
            <div
              className='page-header min-vh-90'
              style={{
                backgroundImage: `url("./assets/img/hero/${img}")`,
              }}
              loading='lazy'
            >
              <span className='mask bg-gradient-dark opacity-6' />
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-7 text-center mx-auto'>
                    <h1 className='text-white pt-3 mt-n5'>{title}</h1>
                    <p className='lead text-white hero-blurb m-auto my-3 px-5 pb-4'>
                      {text}
                    </p>
                    <button
                      className='btn bg-gradient-danger btn-lg'
                      onClick={handleClick}
                    >
                      Get Started
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </header>
        );
      default:
        return (
          <header>
            <div
              className={`page-header min-vh-${heroHeight}`}
              style={{
                backgroundImage: `url("../../assets/img/hero/${img}")`,
                backgroundSize: 'cover',
              }}
              loading='lazy'
            >
              <span className='mask bg-gradient-dark opacity-6' />
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-7 d-flex justify-content-center flex-column'>
                    <h1 className='text-white mb-0'>{title}</h1>
                    <p className='lead hero--inner-blurb pe-sm-5 me-sm-5 text-white opacity-8'>
                      {text}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </header>
        );
    }
  };

  return renderHeader(home);
};

export default Header;
