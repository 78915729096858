import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Page from '../Utilities/Page';
import Header from '../Utilities/Header';
import PlaylistMain from './PlaylistMain';
import ModalTop10 from '../Modals/ModalTop10';
import ModalReferral2 from '../Modals/ModalReferral2';
import { heroOpts } from '../../constants/heroOpts';

const Playlists = () => {
  const { showModal, referral } = useSelector((state) => state.chartify);
  const options = heroOpts.get('playlists');

  // * toggle modal
  useEffect(() => {
    if (showModal) document.body.style.overflow = 'hidden';
    if (!showModal) document.body.style.overflow = 'unset';
  }, [showModal]);

  const modal = referral ? <ModalReferral2 /> : <ModalTop10 />;

  return (
    <>
      <Page
        hero={
          <Header img={options.img} title={options.title} text={options.text} />
        }
        main={<PlaylistMain />}
        modal={modal}
        // modal={<ModalTop10 />}
      />
    </>
  );
};

export default Playlists;
