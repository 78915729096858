import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// async function for one time fetch of content from an express route

export const useAsyncFetch = (action, payload = null) => {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        dispatch(action());
      } catch (err) {
        console.log(err.message);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
