import React from 'react';
import ArticleBtns from '../Buttons/ArticleBtns';
import { formatDate } from '../../functions/formatDate';
import { categoryColor } from '../../functions/categoryColor';

const Article = ({ article }) => {
  return (
    <div className='container'>
      <div className='row'>
        <div className='col-lg-12 pb-7 mobile--pb3'>
          <div className='row'>
            <div className='col-lg-6 justify-content-start d-flex flex-column'>
              <div className='card border-radius-lg'>
                <div className='d-block blur-shadow-image'>
                  <img
                    src={article.photoURL}
                    alt='album cover'
                    className='img-fluid border-radius-lg'
                  />
                </div>
              </div>
            </div>
            <div className='col-lg-6 justify-content-center d-flex flex-column ps-lg-5 pt-lg-0 pt-3'>
              <h6
                className={`text-uppercase text-sm font-weight-bold text-gradient-primary ${categoryColor(
                  article.category
                )}`}
              >
                {article.category}
              </h6>
              <h3>{article.title}</h3>
              <div className='d-flex justify-content-between pb-4 article-credits'>
                <small className='author'>
                  by{' '}
                  <div className='ms-1'>
                    <span className='font-weight-bold text-primary'>
                      {article.author}
                    </span>
                  </div>
                </small>
                <span className='text-uppercase text-xs  article-date'>
                  {formatDate(article.date)}
                </span>
              </div>
              <p className='article-body'>{article.body}</p>
              <ArticleBtns article={article} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Article;
