import React from 'react';
import { useDispatch } from 'react-redux';
import { setPlaylistTitle } from '../../redux/slices/spotify';

const PlaylistTitle = () => {
  const dispatch = useDispatch();

  return (
    <div style={{ width: '100%' }}>
      <h6 className='mt-4'>Playlist Title</h6>
      <section className=' '>
        <div className=''>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='input-group input-group-outline'>
                <label className='form-label'></label>
                <input
                  className='form-control'
                  type='text'
                  onChange={(e) => {
                    dispatch(setPlaylistTitle(e.target.value));
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PlaylistTitle;
