import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Stack, TextField } from '@mui/material';
import { setSelectedDate, setDateError } from '../../redux/slices/spotify';

const Calendar = () => {
  const { selectedDate } = useSelector((state) => state.spotify);
  const dispatch = useDispatch();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={4} sx={{ width: '250px' }}>
        <DatePicker
          label='Choose a date'
          minDate={new Date('1958-08-05')}
          maxDate={new Date()}
          dateFormat='MM/dd/yyyy'
          allowKeyboardControl={false}
          value={selectedDate}
          onChange={(newValue) => {
            dispatch(setDateError(false));
            console.log(newValue);

            const date = newValue.toISOString();
            dispatch(setSelectedDate(date));
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </Stack>
    </LocalizationProvider>
  );
};

export default Calendar;
