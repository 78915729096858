// * useRedirect automatically navigates user to /playlists and autofills playlist form fields. Used when user is not logged in and is accessing /playlists from a public page OR when being referred to the creator through a custom url via the /referral route.

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useAuth from './useAuth';
import { setShowModal } from '../redux/slices/chartify';
import { setReferral } from '../redux/slices/chartify';
import { setSelectedDate } from '../redux/slices/spotify';

export const useRedirect = () => {
  const user = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // * session data
  const redirect = sessionStorage.getItem('redirect');
  const playlistDate = sessionStorage.getItem('playlistDate');
  const referral = sessionStorage.getItem('referral');

  // * redirect user after login
  useEffect(() => {
    if (user && redirect && playlistDate) {
      dispatch(setShowModal(redirect));
      dispatch(setReferral(referral));
      dispatch(setSelectedDate(playlistDate));
      sessionStorage.clear();
      navigate('/playlists');
    } else if (user && redirect) {
      sessionStorage.clear();
      navigate('/playlists');
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, redirect, playlistDate]);
};
