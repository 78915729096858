import React, { useState } from 'react';
import TabArticle from './TabArticle';
import TabPlaylists from './TabPlaylists';
import TabBillboard from './TabBillboard';

const AdminTabs = () => {
  const [activeTab, setActiveTab] = useState('tab-1');

  // * tab navigation
  const handleTab1 = () => setActiveTab('tab-1');
  const handleTab2 = () => setActiveTab('tab-2');
  const handleTab3 = () => setActiveTab('tab-3');

  const displayTab = () => {
    if (activeTab === 'tab-1') return <TabArticle />;
    if (activeTab === 'tab-2') return <TabPlaylists />;
    if (activeTab === 'tab-3') return <TabBillboard />;
  };

  return (
    <>
      <div className='row'>
        <div className='col-lg-4 mx-auto'>
          <div className='nav-wrapper position-relative end-0'>
            <ul className='nav nav-pills nav-fill p-1 admin-tab'>
              <li
                className={
                  activeTab === 'tab-1' ? 'nav-item active' : 'nav-item'
                }
                onClick={handleTab1}
              >
                <div
                  className='nav-link mb-0 px-0 py-1'
                  aria-controls='articles'
                  aria-selected='true'
                >
                  Articles
                </div>
              </li>
              <li
                className={
                  activeTab === 'tab-2' ? 'nav-item active' : 'nav-item'
                }
                onClick={handleTab2}
              >
                <div
                  className='nav-link mb-0 px-0 py-1'
                  aria-controls='playlists'
                  aria-selected='false'
                >
                  Playlists
                </div>
              </li>
              <li
                className={
                  activeTab === 'tab-3' ? 'nav-item active' : 'nav-item'
                }
                onClick={handleTab3}
              >
                <div
                  className='nav-link mb-0 px-0 py-1'
                  aria-controls='billboard'
                  aria-selected='false'
                >
                  Billboard
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {displayTab()}
    </>
  );
};

export default AdminTabs;
