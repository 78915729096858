import React from 'react';
import GraphBar from './GraphBar';
import GraphDonut from './GraphDonut';
import GraphPie from './GraphPie';

const Graphs = ({ data }) => {
  if (!data) return;

  return (
    <>
      <hr className='horizontal dark my-sm-6 my-3 mx-7' />
      <div className='container mobile--flex-ver-c'>
        <div className='row mobile--card-m'>
          <div className='col-lg-8 text-center mx-auto'>
            <p className='mb-1 text-gradient text-primary font-weight-bold text-uppercase'>
              Snapshot
            </p>
            <h3>Playlist Statistics</h3>
          </div>
        </div>
        <div className='row mobile--w-100 mobile--flex-ver-c'>
          <div
            className='col-7 d-flex flex-column justify-content-center mobile--w-100 mobile--m-zero'
            style={{ margin: 'auto' }}
          >
            <GraphBar data={data} />
          </div>
          <div className='col-5 mobile--w-100 mobile--m-zero mobile--card-m'>
            <GraphDonut data={data} />
            <GraphPie data={data} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Graphs;
