import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import BlogArticle from './BlogArticle';
import Subscribe from '../MailChimp/Subscribe';
import RecentPosts from './RecentPosts';
import MorePosts from './MorePosts';
import { useAsyncFetch } from '../../hooks/useAsyncFetch';
import { fetchArticles } from '../../redux/slices/articles';

const BlogMain = () => {
  // * ----- STATE -----
  const { articles } = useSelector((state) => state);
  const { status } = articles;
  const { lead, recent, more } = articles.articles;

  // * ----- HOOKS -----
  useAsyncFetch(fetchArticles);

  // * ----- RENDER -----
  const renderPage = (lead, recent, more) => {
    if (status !== 'succeeded') {
      return <div></div>;
    } else {
      return (
        <>
          <section className='py-5 mt-5 mobile--p-zero'>
            <BlogArticle article={lead} />
            <Subscribe />
          </section>
          <RecentPosts articles={recent} />
          <MorePosts articles={more.slice(0, 3)} />
          <div className='container d-flex justify-content-center'>
            <Link to='page-2'>
              <button
                type='button'
                className='btn btn-info btn-lg btn--blog-more'
              >
                Read More Stories
              </button>
            </Link>
          </div>
        </>
      );
    }
  };

  return renderPage(lead, recent, more);
};

export default BlogMain;
