import { useEffect } from 'react';
import Rellax from 'rellax';

// * Parallax (custom script for rellax.js init)

export const useRellax = () => {
  useEffect(() => {
    new Rellax('.rellax', { speed: 5 });
  }, []);
};
