import React from 'react';
import { Link } from 'react-router-dom';

const NavLink = ({ to, icon, text, format }) => {
  return (
    <li className='nav-item d-flex px-2'>
      <Link className={`nav-link active d-flex my-auto ${format}`} to={to}>
        <i
          className='material-icons opacity-6 me-1 text-md my-auto'
          aria-hidden='true'
        >
          {icon}
        </i>
        {text}
      </Link>
    </li>
  );
};

export default NavLink;
