import React from 'react';
import UpdateForm from './UpdateForm';
import UpdateCard from './UpdateCard';

const TabBillboard = () => {
  return (
    <section className='py-7'>
      <div className='container'>
        <div className='row justify-content-start'>
          <UpdateForm />
          <UpdateCard />
        </div>
      </div>
    </section>
  );
};

export default TabBillboard;
