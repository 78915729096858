import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setSelectedDate,
  setListSize,
  playlistSearch,
} from '../../redux/slices/spotify';

const UpdateForm = () => {
  // * state
  const { spotify } = useSelector((state) => state);

  // * hooks
  const dispatch = useDispatch();

  useEffect(() => {
    // date calculation sets date to the most recent saturday to match RapidAPIs most recent chart
    const date = new Date();
    const currentDay = date.getDay();

    // ! Date currently sets to the latest saturday. Chart is actually updated on the previous Tuesday. Web scraping solution needed for billboard site to update on Tuesday.
    if (currentDay !== 6) date.setDate(date.getDate() - currentDay - 1);

    dispatch(setSelectedDate(date.toISOString()));
    dispatch(setListSize(40));

    console.log(spotify);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // * helper functions
  const onSubmit = async (e) => {
    e.preventDefault();

    dispatch(playlistSearch(spotify));
  };

  return (
    <div className='col-lg-4'>
      <div className='row justify-content-start'>
        <div className=''>
          <div className='info'>
            <form onSubmit={onSubmit}>
              <h5>Update Billboard Playlists</h5>
              <p>
                This page updates the Billboard Top 10, 20 and 40 playlists on
                the Chartify Spotify account to the current date.
              </p>
              <p>
                Press submit to preview the Top 40. The chart will be parsed
                into seperate playlists on the express server.
              </p>
              <button className='btn btn-primary btn-round mt-4'>
                <i className='material-icons'>send</i> Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateForm;
