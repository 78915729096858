import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserData } from '../../redux/slices/user';
import DashboardIntro from './DashboardIntro';
import Graphs from './Graphs';
import CreatePlaylist from '../Cards/CreatePlaylist';

const DashboardMain = () => {
  // * ----- STATE -----
  const { charts, user } = useSelector((state) => state.user);
  const [eChartState, setEChartState] = useState(null);
  const dispatch = useDispatch();

  // * ----- EFFECTS -----
  // * fetch mongoDB chart data
  useEffect(() => {
    dispatch(fetchUserData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // * useEffect | eliminates infinite loop from setting state
  useEffect(() => {
    if (!charts) return;
    if (charts.length === 0) return;

    // data arrays | each index === #charts/given decade (1950s - 2020s)
    const lengthArray10s = [];
    const lengthArray20s = [];
    const lengthArray40s = [];

    // parse chartData | (format decade & chart length)
    const chartData = charts.map((chart) => {
      const chartFormatted = {
        decade: (Math.floor(+chart.chart.info.date.slice(0, 4) / 10) * 10)
          .toString()
          .slice(2, 4),
        length: chart.chart.length,
      };

      return chartFormatted;
    });

    // fill lengthArrays
    parseLengthData(chartData, '50');
    parseLengthData(chartData, '60');
    parseLengthData(chartData, '70');
    parseLengthData(chartData, '80');
    parseLengthData(chartData, '90');
    parseLengthData(chartData, '00');
    parseLengthData(chartData, '10');
    parseLengthData(chartData, '20');

    const state = {
      chartLengths: {
        top10s: lengthArray10s,
        top20s: lengthArray20s,
        top40s: lengthArray40s,
      },
      decadeCounts: {
        '50s': parseDecadeData(chartData, '50'),
        '60s': parseDecadeData(chartData, '60'),
        '70s': parseDecadeData(chartData, '70'),
        '80s': parseDecadeData(chartData, '80'),
        '90s': parseDecadeData(chartData, '90'),
        '00s': parseDecadeData(chartData, '00'),
        '10s': parseDecadeData(chartData, '10'),
        '20s': parseDecadeData(chartData, '20'),
      },
    };

    // ----- FORMULAS -----
    // chart sizes per decade | add to lenghtArrays
    function parseLengthData(data, decade) {
      const decadeChart = arrayOfChartLengths(data, decade);
      countChartLengths(decadeChart);

      // filter data into an array of chart lengths (ex [10, 10, 40, 20, 40])
      function arrayOfChartLengths(data, decade) {
        return data
          .filter((chart) => chart.decade === decade)
          .map((chart) => chart.length);
      }
      // count instances of each chart length and add to length arrays
      function countChartLengths(chart) {
        filterLengths(chart, 10, lengthArray10s);
        filterLengths(chart, 20, lengthArray20s);
        filterLengths(chart, 40, lengthArray40s);

        function filterLengths(chart, length, array) {
          const arr = chart.filter(
            (currElement) => currElement === length
          ).length;

          array.push(arr);
        }
      }
    }

    // calc charts per decade
    function parseDecadeData(data, decade) {
      return data.filter((chart) => chart.decade === decade).length;
    }

    // ----- SET STATE -----
    setEChartState(state);
  }, [charts]);

  return (
    <div>
      <div className='py-5 mobile--p-zero mobile--mt3'>
        <DashboardIntro user={user.name} data={eChartState} />
        <Graphs data={eChartState} />
      </div>
      <CreatePlaylist text='Add to your data' />
    </div>
  );
};

export default DashboardMain;
