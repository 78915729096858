import React from 'react';
import PostVertical from './PostVertical';
import { renderPosts } from '../../functions/renderPosts';

const RecentPosts = ({ articles }) => {
  return (
    <section className='py-5 mobile--p-zero mobile--mt3'>
      <div className='container'>
        <div className='row'>
          <div className='col-9 text-center mx-auto'>
            <h2 className='mb-1'>Recent posts</h2>
            <p className='mb-6 px-md-6'>
              The latest playlists, charts and updates from Chartify.
            </p>
          </div>
          {renderPosts(articles, PostVertical)}
        </div>
      </div>
    </section>
  );
};

export default RecentPosts;
