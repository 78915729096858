import React from 'react';
import Hot100Btns from './Hot100Btns';

const ArticleBtns = ({ article }) => {
  const { category, playlistURI, date } = article;

  const handleClick = () => {
    sessionStorage.setItem('redirect', true);
    sessionStorage.setItem('playlistDate', date);
    window.location.href = '/auth/spotify';
  };

  // * conditional render of action buttons based on type of post && date. Featured will always link to the target playlist on the Chartify Spotify acct. The Hot 100 category will link to the current playlist if it is the most recent Hot 100 blog post. If not it will autofill the playlist generator.
  const renderBtns = (category, playlistURI) => {
    switch (category) {
      case 'Featured':
        return (
          <a
            href={`https://open.spotify.com/playlist/${playlistURI}`}
            className='btn btn-round btn-primary mx-auto my-2'
          >
            Get Playlist
          </a>
        );
      case 'Hot 100':
        const today = new Date().getTime();
        const playlistDate = new Date(date).getTime();
        const daysElapsed = (today - playlistDate) / (1000 * 60 * 60 * 24);

        if (daysElapsed < 7) {
          return (
            <div className='d-flex justify-content-around pt-3'>
              <Hot100Btns />;
            </div>
          );
        } else {
          return (
            <button
              className='btn btn-round btn-primary mx-auto my-2'
              onClick={handleClick}
            >
              <i className='material-icons'>music_note</i> Create Playlist
            </button>
          );
        }

      default:
        return '';
    }
  };

  return renderBtns(category, playlistURI);
};

export default ArticleBtns;
