import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// * ----- THUNKS -----
// * api urls
const URL = '/api/articles';

// * Thunk for fetching articles from MongoDB
export const fetchArticles = createAsyncThunk('articlesFetch', async () => {
  try {
    const response = await axios.get(URL);
    return response.data;
  } catch (error) {
    return error.message;
  }
});

// * ----- SLICE -----
const articlesSlice = createSlice({
  name: 'articles',
  initialState: {
    articles: {
      lead: null,
      recent: null,
      more: null,
    },
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchArticles.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchArticles.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.articles = action.payload;
      })
      .addCase(fetchArticles.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

// * ----- ACTIONS/REDUCER -----
export default articlesSlice.reducer;
