import LoginBtn from '../components/Buttons/LoginBtn';

export const renderNavLinks = (NavLink, user, options, format = null) => {
  // * conditional links | login & admin status
  const renderLinks = (options, auth, format, admin = false) => {
    return (
      options
        // eslint-disable-next-line array-callback-return
        .filter((link) => {
          if (auth === false) return link.auth === auth;
          if (admin === true) return link;
          if (auth === true && admin === false) return !link.admin;
        })
        .map((link) => (
          <NavLink
            to={link.to}
            icon={link.icon}
            text={link.text}
            format={format}
            key={link.text}
          />
        ))
    );
  };

  switch (user) {
    case null:
      return;
    case false:
      return (
        <>
          {renderLinks(options, false, format)}
          <LoginBtn loggedIn={user} />
        </>
      );
    default:
      return (
        <>
          {user.admin
            ? renderLinks(options, true, format, true)
            : renderLinks(options, true, format)}
          <LoginBtn loggedIn={user} />
        </>
      );
  }
};
