import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// * ----- THUNKS -----
// * api urls
const SEARCH_URL = '/api/playlist/search';
const CREATE_URL = '/api/playlist/create';
const UPDATE_URL = '/api/playlist/update';
const GET_TOP5_URL = '/api/playlist/fetch';

// * Thunk for playlist login status
export const playlistSearch = createAsyncThunk(
  'playlistSearch',
  async (spotifyState) => {
    try {
      const response = await axios.post(SEARCH_URL, spotifyState);
      return response.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const playlistCreate = createAsyncThunk(
  'playlistCreate',
  async (spotifyState) => {
    try {
      const response = await axios.post(CREATE_URL, spotifyState);

      // * ADD chart to user record in mongo after successful spotify playlist creation
      if (response.data) {
        try {
          const URL = '/api/dashboard';
          await axios.post(URL, spotifyState.billboard);
        } catch (error) {
          console.log(error.message);
        }
      }

      return response.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const updateTopLists = createAsyncThunk(
  'updateHot100',
  async (spotifyState) => {
    try {
      const response = await axios.post(UPDATE_URL, spotifyState);
      return response.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const fetchTop5 = createAsyncThunk(
  'fetchTop5',
  async (playlistURI, thunkAPI) => {
    try {
      thunkAPI.dispatch(setStatus('idle'));
      const response = await axios.get(GET_TOP5_URL, playlistURI);
      return response.data;
    } catch (error) {
      return error.message;
    }
  }
);

const spotifySlice = createSlice({
  name: 'spotify',
  initialState: {
    // ! reorganize state so that each function has its own object (search, top5, etc)
    selectedDate: null,
    listSize: 10,
    billboard: null,
    playlistURI: null,
    playlistTitle: null,
    playlistDesc: null,
    dateError: false,
    top5Tracks: null,
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
    },
    setListSize: (state, action) => {
      state.listSize = action.payload;
    },
    setDateError: (state, action) => {
      state.dateError = action.payload;
    },
    setPlaylistURI: (state, action) => {
      state.playlistURI = action.payload;
    },
    setPlaylistTitle: (state, action) => {
      state.playlistTitle = action.payload;
    },
    setPlaylistDesc: (state, action) => {
      state.playlistDesc = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      // * search for playlist (rapidAPI & spotify URIs)
      .addCase(playlistSearch.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(playlistSearch.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // state.playlist = action.payload || false;
        // ! distribute payload to playlist & NEW billboard state
        state.playlist = action.payload.playlist || false;
        state.billboard = action.payload.billboard || false;
      })
      .addCase(playlistSearch.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.playlist = action.payload || false;
      })
      // * create spotify playlist w tracks
      .addCase(playlistCreate.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(playlistCreate.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.playlistURI = action.payload;
      })
      .addCase(playlistCreate.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        // state.playlist = action.payload || false;
      })
      // * update Billboard weekly lists w tracks
      .addCase(updateTopLists.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateTopLists.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.playlistURI = action.payload;
      })
      .addCase(updateTopLists.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        // state.playlist = action.payload || false;
      })
      // * fetch top 5 tracks of the week
      .addCase(fetchTop5.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTop5.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.top5Tracks = action.payload;
      })
      .addCase(fetchTop5.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const {
  setSelectedDate,
  setListSize,
  setDateError,
  setPlaylistURI,
  setPlaylistTitle,
  setPlaylistDesc,
  setStatus,
} = spotifySlice.actions;
export default spotifySlice.reducer;
