import React from 'react';
import ReactEcharts from 'echarts-for-react';

const GraphPie = ({ data }) => {
  if (!data) return;

  const { decadeCounts } = data;
  const graphData = [];

  for (const [key, value] of Object.entries(decadeCounts)) {
    graphData.push({ value, name: key });
  }

  const option = {
    title: {
      text: 'by Decade',
      left: 'center',
    },
    tooltip: {
      trigger: 'item',
    },
    // legend: {
    //   orient: 'vertical',
    //   left: 'right',
    // },
    series: [
      {
        name: '',
        type: 'pie',
        radius: '70%',
        data: graphData,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };

  return <ReactEcharts option={option} />;
};

export default GraphPie;
