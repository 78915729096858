import React from 'react';

const Subscribe = () => {
  return (
    <div className='page-header bg-gradient-info min-height-200 mx-n3'>
      <div className='col-lg-8 col-md-9 col-sm-10 d-flex justify-content-center flex-column m-auto custom--z-index'>
        <form
          action='https://media.us18.list-manage.com/subscribe/post'
          method='post'
        >
          <div>
            <input
              type='hidden'
              name='u'
              defaultValue='e62f555f23a5e4e115eb2f6d2'
            />
            <input type='hidden' name='id' defaultValue='53a9c86455' />
          </div>

          <div className='row'>
            <div className='col-lg-7 col-md-7 col-sm-6 col-xs-5 ms-auto'>
              <div className='footer--custom-margin'>
                <div className='input-group input-group-outline'>
                  <label className='form-label'>Email Here...</label>

                  <input
                    type='email'
                    className='form-control text-white'
                    autoCapitalize='off'
                    autoCorrect='off'
                    name='MERGE0'
                    id='MERGE0'
                    size={25}
                  />
                  {/* <div className='error'>*|HTML:EMAILERROR|*</div> */}
                </div>
              </div>
            </div>
            <div className='col-xl-4 col-lg-5 col-md-5 col-sm-5 footer--custom-spacing'>
              <div className='footer--custom-margin'>
                <div className='footer--custom-btn-spacing'>
                  <div
                    className=''
                    style={{ position: 'absolute', left: '-5000px' }}
                    aria-hidden='true'
                  >
                    <input
                      type='text'
                      name='b_e62f555f23a5e4e115eb2f6d2_53a9c86455'
                      tabIndex={-1}
                      value=''
                      readOnly
                    />
                  </div>
                  <input
                    type='submit'
                    className='btn bg-gradient-warning w-75 footer-custom-btn'
                    name='submit'
                    value='Subscribe'
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Subscribe;
