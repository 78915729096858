import React from 'react';

const ControlButtons = ({ articles }) => {
  const renderButtons = (articles) => {
    const btns = articles.map((_, index) => {
      if (index === 0) {
        return (
          <li
            className='page-item'
            data-bs-target='#carousel-body'
            data-bs-slide-to={index}
            key={index + 1}
          >
            <div className='page-link custom--pagination-li'>
              <span className='custom--pagination text-primary'>
                {(index + 1).toString()}
              </span>
            </div>
          </li>
        );
      } else {
        return (
          <li
            className='page-item'
            data-bs-target='#carousel-body'
            data-bs-slide-to={index}
            key={index + 1}
          >
            <div className='page-link custom--pagination-li'>
              <span className='custom--pagination text-primary'>
                {(index + 1).toString()}
              </span>
            </div>
          </li>
        );
      }
    });

    return (
      <ul className='pagination mobile--m-zero mobile--carousel-page'>
        {btns}
      </ul>
    );
  };

  return (
    <div
      className='row mt-10 mobile--carousel-btn'
      aria-label='next article buttons'
    >
      {renderButtons(articles)}
    </div>
  );
};

export default ControlButtons;
