import React from 'react';
import ReactEcharts from 'echarts-for-react';

const GraphBar = ({ data }) => {
  if (!data) return;

  const { top10s, top20s, top40s } = data.chartLengths;

  const option = {
    title: {
      text: 'Summary',
      // left: 'center',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        // Use axis to trigger tooltip
        type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },
    // legend: {},
    xAxis: {
      type: 'category',
      data: ['50s', '60s', '70s', '80s', '90s', '00s', '10s', '20s'],
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: 'Top 10',
        type: 'bar',
        stack: 'x',
        emphasis: {
          focus: 'series',
        },
        data: top10s,
      },
      {
        name: 'Top 20',
        type: 'bar',
        stack: 'x',
        emphasis: {
          focus: 'series',
        },
        data: top20s,
      },
      {
        name: 'Top 40',
        type: 'bar',
        stack: 'x',
        emphasis: {
          focus: 'series',
        },
        data: top40s,
      },
    ],
  };

  return <ReactEcharts option={option} />;
};

export default GraphBar;
