import React from 'react';

const LoginBtn = ({ loggedIn }) => {
  const renderButton = (loggedIn) => {
    switch (loggedIn) {
      case null:
        return;
      case false:
        return (
          <li className='nav-item d-flex ps-2 login-sidenav'>
            <a
              href='/auth/spotify'
              className='btn btn-round btn-primary my-auto d-flex align-items-center'
            >
              <span className='fab fa-spotify fa-2x pe-2' /> Spotify Login
            </a>
          </li>
        );
      default:
        return (
          <li className='nav-item d-flex ps-2 login-sidenav'>
            <a
              href='/api/logout'
              className='btn btn-round btn-primary my-auto d-flex align-items-center'
            >
              <span className='fab fa-spotify fa-2x pe-2' /> Logout
            </a>
          </li>
        );
    }
  };

  return renderButton(loggedIn);
};

export default LoginBtn;
