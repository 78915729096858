import React from 'react';

const Features = () => {
  return (
    <div className='col-lg-6 mb-7'>
      <div className='row justify-content-start'>
        <div className='pb-5 text-start border-radius-lg'>
          <h2 className=''>Future features</h2>
          <p className='text-dark text-lg mt-3'>
            Here's a few of the new the features we're planning. Make them
            happen by supporting us today...
          </p>
        </div>
        <div className='col-md-6 mobile--mb1'>
          <div className='info'>
            <i className='material-icons text-3xl text-gradient text-primary mb-3'>
              format_list_numbered
            </i>
            <h5>More charts</h5>
            <p>
              What about R&B, Rock, Country? Future searches will let you zero
              in on the genre you love.
            </p>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='info'>
            <i className='material-icons text-3xl text-gradient text-primary mb-3'>
              music_video
            </i>
            <h5>New Playlists</h5>
            <p>
              Remember that epic year of music? Spotlighted years will let you
              relive the greatest years in music one week at time.
            </p>
          </div>
        </div>
      </div>
      <div className='row justify-content-start mt-4'>
        <div className='col-md-6 mobile--mb1'>
          <div className='info'>
            <i className='material-icons text-3xl text-gradient text-primary mb-3'>
              mail
            </i>
            <h5>Email updates</h5>
            <p>
              Get Chartify's newest playlists and updates in your inbox. The
              newsletter is coming!
            </p>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='info'>
            <i className='material-icons text-3xl text-gradient text-primary mb-3'>
              lightbulb
            </i>
            <h5>New ideas</h5>
            <p>
              Mobile app and other features are in the works. Seek us out on
              social media.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
