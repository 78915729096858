import React from 'react';

const ControlArrows = () => {
  return (
    <div className='position-relative mt-n4'>
      <a
        className='carousel-control-prev text-info position-absolute bottom-0 end-0 ms-auto'
        href='#carousel-body'
        role='button'
        data-bs-slide='prev'
      >
        <i className='fas fa-2x fa-chevron-left position-absolute start-0 ms-3 mt-7 mt-md-0 ms-n5 ms-md-0' />
        <span className='sr-only'>Previous</span>
      </a>
      <a
        className='carousel-control-next text-info position-absolute bottom-0 end-0 ms-auto ms-4'
        href='#carousel-body'
        role='button'
        data-bs-slide='next'
      >
        <i className='fas fa-2x fa-chevron-right mt-7 mt-md-0' />
        <span className='sr-only'>Next</span>
      </a>
    </div>
  );
};

export default ControlArrows;
