import React from 'react';
import { useSelector } from 'react-redux';

const PlaylistCardFront = () => {
  // * ----- STATE -----
  const { playlistURI } = useSelector((state) => state.spotify);

  // * ----- HELPERS -----
  const renderFrontCard = (playlistURI) => {
    switch (playlistURI) {
      case null:
        return (
          <div className='card bg-gradient-primary custom-card--front custom-card--playlist'>
            <div className='card-body'>
              <h6 className='card-category'>Chartify</h6>

              <h3 className='card-title pb-4'>
                Music History at Your Fingertips
              </h3>
              <div className='px-3'>
                <p className='card-description text-white'>
                  {`1) Select a date and chart length in the form.`}
                </p>
                <p className='card-description text-white'>
                  {`2) Preview of your playlist.`}
                </p>
                <p className='card-description text-white'>
                  {`3) Add the playlist to your Spotify account or search again.`}
                </p>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className='card custom-card--front'>
            <iframe
              title='user playlist'
              style={{ borderRadius: 12 }}
              src={`https://open.spotify.com/embed/playlist/${playlistURI}?utm_source=generator`}
              width='100%'
              height='100%'
              allowFullScreen=''
              allow='autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture'
              loading='lazy'
            ></iframe>
          </div>
        );
    }
  };

  return renderFrontCard(playlistURI);
};

export default PlaylistCardFront;
