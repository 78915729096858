// * format date for posts
export const formatDate = (ISOdate) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const date = new Date(ISOdate);
  let day = date.getDate();
  let month = months[date.getMonth()];
  let year = date.getFullYear();

  return `${month} ${day}, ${year}`;
};
