import React from 'react';

const Hot100Btns = () => {
  return (
    <>
      <a
        href='https://open.spotify.com/playlist/6cs0KhbwTBOTLH38e0GKUF?si=b51780d59b554220'
        className='btn btn-round btn-light btn-just-icon btn-custom-w '
      >
        Top 10
      </a>
      <a
        href='https://open.spotify.com/playlist/2LRWkANdwAG5INUiYTndrb?si=4786961a1ff04ab0'
        className='btn btn-round btn-secondary btn-just-icon btn-custom-w'
      >
        Top 20
      </a>
      <a
        href='https://open.spotify.com/playlist/54FbeyJ3LQJx5wRonGBMua?si=981a31cae8a94650'
        className='btn btn-round btn-info btn-just-icon btn-custom-w '
      >
        Top 40
      </a>
    </>
  );
};

export default Hot100Btns;
