import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LocalsLogo } from '../Utilities/svg/l-letter.svg';

const FooterMain = () => {
  return (
    <footer className='footer pb-5 pt-8 bg-white mt-n5 position-relative mobile--footer-p'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-3 mobile--flex-hor-c'>
            <h6 className='font-weight-bolder mb-lg-4 mb-3 mobile--footer-m'>
              Chartify
            </h6>
          </div>
          <div className='col-lg-6 text-center'>
            <ul className='nav flex-row align-items-center mb-5 mt-sm-0 justify-content-center mobile--footer-m'>
              <li className='nav-item'>
                <Link className='nav-link' to='/'>
                  Home
                </Link>
              </li>

              <li className='nav-item'>
                <Link className='nav-link' to='/blog'>
                  Blog
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/support'>
                  Support
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/privacy'>
                  Privacy
                </Link>
              </li>
            </ul>
            <p className='mb-0 mobile--footer-m'>Copyright © 2023 Chartify.</p>
          </div>
          <div className='col-lg-3 text-end mobile--flex-hor-c d-flex justify-content-end'>
            <a
              href='https://twitter.com/chartifymedia'
              target='_blank'
              rel='noreferrer'
              className='text-secondary me-xl-4 me-4'
            >
              <span className='fab fa-twitter text-lg' />
            </a>
            <a
              href='https://www.facebook.com/chartifymedia'
              target='_blank'
              rel='noreferrer'
              className='text-secondary me-xl-4 me-4'
            >
              <span className='fab fa-facebook text-lg' />
            </a>

            <a
              href='https://open.spotify.com/user/31cwexwscswzbjixh2gdqul6hys4'
              target='_blank'
              rel='noreferrer'
              className='text-secondary me-xl-4 me-4'
            >
              <span className='fab fa-spotify text-lg' />
            </a>
            <a
              href='https://chartify.locals.com/'
              target='_blank'
              rel='noreferrer'
              className='text-secondary locals-logo-container'
            >
              <div>
                <LocalsLogo />
              </div>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterMain;
