import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { playlistCreate } from '../../redux/slices/spotify';
import { renderPlaylist } from '../../functions/renderPlaylist';

// * ----- NOTE -----
// the playlist is saved to the mongo user record inside the playlistCreate asyncThunk function. Go to the spotify slice to access code

const PlaylistCardBack = () => {
  // * ----- STATE -----
  const { spotify } = useSelector((state) => state);
  const { playlist } = spotify;

  console.log(playlist);
  // * ----- HOOKS -----
  const dispatch = useDispatch();

  // * ----- HELPERS -----
  const renderCardBack = (playlist) => {
    switch (playlist) {
      case null:
        return (
          <div className='card bg-gradient-primary custom-card--back'>
            <div className='card-body playlist-preview--card d-flex flex-column'>
              <div>
                <h6 className='card-category text-info'>Chartify</h6>
                <div>
                  <h3 className='card-title'>Spotify Playlist Preview</h3>
                </div>

                <div>Loading list ...</div>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className='card bg-gradient-primary custom-card--back'>
            <div className='card-body playlist-preview--card d-flex flex-column'>
              <div>
                <h6 className='card-category text-info'>Chartify</h6>
                <div>
                  <h3 className='card-title'>Spotify Playlist Preview</h3>
                </div>

                <div className='table-responsive playlist-preview--table'>
                  {renderPlaylist(playlist)}
                </div>
              </div>
              <div className='mt-4 d-flex flex-column align-items-center'>
                <p className='text-white'>
                  Click to add playlist to your Spotify account
                </p>
                <div className=''>
                  <button
                    onClick={() => {
                      dispatch(playlistCreate(spotify));
                    }}
                    className='btn bg-gradient-warning mt-2'
                    type='submit'
                    name='action'
                    style={{ marginTop: '20px' }}
                  >
                    Create Playlist
                  </button>
                </div>
                <span className='text-xs text-white'>
                  <i
                    className='material-icons secondary-content'
                    style={{ color: 'red' }}
                  >
                    warning
                  </i>{' '}
                  indicates track not found
                </span>
              </div>
            </div>
          </div>
        );
    }
  };

  return renderCardBack(playlist);
};

export default PlaylistCardBack;
