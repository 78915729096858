import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setShowModal } from '../../redux/slices/chartify';

// * showModal state && modal param control availability of modal.

const Referral = ({ modal }) => {
  const { showModal } = useSelector((state) => state.chartify);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setShowModal(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{showModal && modal}</>;
};

export default Referral;
