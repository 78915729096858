import React from 'react';
import Features from './Features';
import Contribute from './Contribute';
import Team from './Team';

const SupportMain = () => {
  return (
    <section className='py-5'>
      <div className='container'>
        <section className=''>
          <div className='container mobile--p-zero'>
            <div className='row align-items-start'>
              <Features />
              <Contribute />
            </div>
          </div>
        </section>
      </div>
      <Team />
    </section>
  );
};

export default SupportMain;
