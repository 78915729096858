import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/user';
import spotifyReducer from './slices/spotify';
import articlesReducer from './slices/articles';
import chartifyReducer from './slices/chartify';

export default configureStore({
  reducer: {
    user: userReducer,
    spotify: spotifyReducer,
    articles: articlesReducer,
    chartify: chartifyReducer,
  },
});
