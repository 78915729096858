import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Calendar from '../Utilities/Calendar';
import {
  setListSize,
  playlistSearch,
  setDateError,
} from '../../redux/slices/spotify';

const PlaylistForm = ({ titleField, descField, disabled }) => {
  // * ----- STATE -----
  const { spotify } = useSelector((state) => state);
  const { dateError } = spotify;

  // * ----- HOOKS -----
  const dispatch = useDispatch();

  // * ----- HELPERS -----
  // * submit form
  const onSubmit = async (e) => {
    e.preventDefault();

    if (!spotify.selectedDate) {
      dispatch(setDateError(true));
      return;
    }

    // * 1. FETCH tracklist (RapidAPI)
    // * 2. FETCH track URIs (Spotify)
    // * 3. RETURN RapidAPI chart & Spotify URIs (obj)
    dispatch(playlistSearch(spotify));
  };

  // * render error
  const renderDateError = (dateError) => {
    return dateError ? (
      <div className='error-date' style={{ color: 'red' }}>
        * Please select a date
      </div>
    ) : (
      ''
    );
  };

  return (
    <div className='col-lg-4'>
      <div className='row justify-content-start'>
        <div className=''>
          <div className='info'>
            <form onSubmit={onSubmit}>
              <h5>Create Your Playlist</h5>
              <p>
                Select the date and length of playlist you want. Chartify will
                create the chart in ascending order - from #1 to end.
              </p>
              {titleField}
              {descField}
              <h6 className='mt-4'>Select chart size:</h6>
              <div
                onChange={(e) => {
                  dispatch(setListSize(e.target.value));
                }}
              >
                <div>
                  <div className='form-check form-check-radio'>
                    <label className='form-check-label'>
                      <input
                        className='form-check-input me-2'
                        name='list'
                        type='radio'
                        value='10'
                        id='exampleRadios1'
                        required
                        defaultChecked
                      />
                      Top 10
                      <span className='circle ms-4'>
                        <span className='check' />
                      </span>
                    </label>
                  </div>
                  <div className='form-check form-check-radio'>
                    <label className='form-check-label'>
                      <input
                        className='form-check-input me-2'
                        name='list'
                        type='radio'
                        value='20'
                        id='exampleRadios2'
                        required
                      />
                      Top 20
                      <span className='circle'>
                        <span className='check' />
                      </span>
                    </label>
                  </div>
                  <div className='form-check form-check-radio'>
                    <label className='form-check-label'>
                      <input
                        className='form-check-input me-2'
                        name='list'
                        type='radio'
                        value='40'
                        id='exampleRadios1'
                        required
                      />
                      Top 40
                      <span className='circle'>
                        <span className='check' />
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <h6 className='mt-4'>Select date:</h6>
              <Calendar />
              {renderDateError(dateError)}
              <button className='btn btn-primary btn-round mt-4'>
                <i className='material-icons'>send</i> Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaylistForm;
