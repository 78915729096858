import { useEffect } from 'react';

// * custom hook to slide mobile side nav into page and reveal menu

export const useSideNav = () => {
  useEffect(() => {
    const navbar = document.querySelector('.slide-nav');
    const toggler = document.querySelector('.toggler');

    toggler.addEventListener('click', () => {
      if (toggler.checked === true) {
        navbar.style.transform = 'translateX(-230px)';
      }
      if (toggler.checked === false) {
        navbar.style.transform = 'translateX(0)';
      }
    });
  });
};
