import React from 'react';
import { useLocation } from 'react-router-dom';
import LoginBtn from '../Buttons/LoginBtn';

// * modal appears when referred to Chartify via a social media link. base url format - /referral/source=twitter&detail=with+or+without+you&date=1987-04-06

const ModalReferral = () => {
  const { pathname } = useLocation();

  const urlData = pathname.slice(10).split('&');
  const detail = urlData[1]
    .slice(7)
    .split('+')
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ');
  const date = new Date(urlData[2].slice(5)).toISOString();

  // * close modal
  // const handleClick = () => {
  //   dispatch(setShowModal(false));
  // };

  const handleClick = () => {
    sessionStorage.setItem('redirect', true);
    sessionStorage.setItem('playlistDate', date);
    sessionStorage.setItem('referral', true);
    window.location.href = '/auth/spotify';
  };

  return (
    <div className='modal--overlay' onClick={handleClick}>
      <div
        className='card modal--redirect mobile--modal'
        style={{ width: '40rem' }}
      >
        <div className='card-body'>
          <h4 className='card-title text-center'>Welcome to Chartify!</h4>
          <h6 className='card-subtitle pb-2 text-muted text-center'>
            Get the featured chart for{' '}
            <span className='text-primary'>{detail}</span>.
          </h6>
          <hr className='horizontal dark mb-3' />
          <p className='card-text text-center pb-3'>
            Login with your Spotify account. You'll be taken to our playlist
            creator next.
          </p>
          <div className='d-flex justify-content-center'>
            <LoginBtn className='text-center' loggedIn={false} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalReferral;
