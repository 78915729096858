// * helper for rendering playlist preview on the back of the rotating card on both /playlists and /admin (playlists tab)

export const renderPlaylist = (playlist) => {
  if (!playlist) return;

  const list = playlist.map((song, i) => {
    return (
      <tr key={i}>
        <td className='text-center text-white'>{`${i + 1}`}</td>
        <td>
          <div className='song-title text-white'>{`${song.title}`}</div>
        </td>
        <td>
          <div className='song-artist text-white'>{`${song.artist}`}</div>
        </td>
        <td>
          {song.uri ? (
            <span className='fab fa-spotify text-white' />
          ) : (
            // <i className='material-icons secondary-content text-white'>
            //   audiotrack
            // </i>
            <i
              className='material-icons secondary-content'
              style={{ color: 'red' }}
            >
              warning
            </i>
          )}
        </td>
      </tr>
    );
  });

  return (
    <table className='table'>
      <tbody>{list}</tbody>
    </table>
  );
};
