import React from 'react';
import { useSelector } from 'react-redux';
import FooterMain from './FooterMain';
import SubscribeFtr from '../MailChimp/SubscribeFtr';

const Footer = () => {
  const { showModal } = useSelector((state) => state.chartify);

  return (
    <div className={showModal ? 'footer-hidden' : ''}>
      <SubscribeFtr />
      <FooterMain />
    </div>
  );
};

export default Footer;
