export const mailchimpPage = new Map([
  [
    'confirm',
    {
      header: 'Email Confirmation',
      title: 'Almost finished...',
      p1: 'Check your inbox!',
      p2: "We need to confirm your email address. Click the link in the email we just sent you. Check your spam folder if you don't see it.",
      img: '../../assets/img/graphics/email-alert.png',
    },
  ],
  [
    'thanks',
    {
      header: 'Thanks for Subscribing!',
      title: "You're ready to go...",
      p1: 'Stay tunes for new features, playlists, and more content. Coming soon!',
      p2: '',
      img: '../../assets/img/graphics/email-confirm.png',
    },
  ],
]);
