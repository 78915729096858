import React from 'react';

const MCMain = ({ title, p1, p2, img }) => {
  return (
    <section className='py-5 min-vh-45'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 px-7 pb-7'>
            <div className='row d-flex justify-content-between'>
              <div className='col-lg-6 justify-content-start d-flex flex-column'>
                <div>
                  <h2 className='mb-4'>{title}</h2>
                  <p className='mb-3'>{p1}</p>
                  <p className='mb-3'>{p2}</p>
                </div>
              </div>
              <div className='col-lg-6 justify-content-start d-flex flex-column'>
                <div className='d-flex flex-column' style={{ width: '20rem' }}>
                  <img src={img} alt='envelope' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MCMain;
