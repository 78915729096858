import React from 'react';
import { Link } from 'react-router-dom';

const SupportCard = () => {
  return (
    <div className='row justify-space-between my-5 mobile--card-m'>
      <div className='col-12 mx-auto'>
        <div className='bg-gradient-danger text-center py-3 border-radius-lg'>
          <h4 className='text-white mb-1'>Contribute</h4>
          <p className='text-white'>Help build new features on Chartify</p>
          <hr className='horizontal dark' />
          <div className='w-100'>
            <Link to='/support' className=''>
              <button className='btn btn-round btn-info btn-custom-w my-2'>
                Support
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportCard;
