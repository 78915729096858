import React from 'react';

const Team = () => {
  return (
    <section className='pb-5 position-relative bg-gradient-info mx-n3'>
      <div className='container'>
        {/* TITLE */}
        <div className='row'>
          <div className='col-md-8 text-start mb-3 mt-5'>
            <h3 className='text-white z-index-1 position-relative'>
              Profile and Contact
            </h3>
            <p className='text-white opacity-8 mb-5'>
              Chartify began as a window to great music. That vision guides
              everything here.
            </p>
          </div>
        </div>

        {/* ROW 1 */}
        <div className='row'>
          {/* PROFILE 1 */}
          <div className='col-lg-6 col-12'>
            <div className='card card-profile mt-4'>
              <div className='row'>
                <div className='col-lg-4 col-md-6 col-12 mt-n5'>
                  <div>
                    <div className='p-3 pe-md-0'>
                      <img
                        className='w-100 border-radius-md shadow-lg'
                        src='../assets/img/kori-brus.jpg'
                        alt='Kori Brus'
                      />
                    </div>
                  </div>
                </div>
                <div className='col-lg-8 col-md-6 col-12 my-auto'>
                  <div className='card-body ps-lg-0'>
                    <h5 className='mb-0'>Kori Brus</h5>
                    <h6 className='text-info'>Creator & Developer </h6>
                    <p className='mb-0'>
                      Programmer, communications professional and music lover.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* CONTACT SOCIAL */}
          <div className='col-lg-6 ms-auto text-lg-end text-center d-flex flex-column justify-content-end'>
            <div>
              <h6 className='text-uppercase pt-5 mb-2 text-white'>
                Connect and follow
              </h6>
              <p className='text-white opacity-8 mb-4 pb-2'>
                Get in touch or follow Chartify on social media.
              </p>
            </div>
            <div>
              <a
                href='https://www.linkedin.com/in/koribrus/'
                target='_blank'
                rel='noreferrer'
                className='text-primary me-xl-4 me-4 opacity-8'
              >
                <span className='fab fa-linkedin' />
              </a>
              <a
                href='https://twitter.com/koribrus'
                target='_blank'
                rel='noreferrer'
                className='text-primary me-xl-4 me-4 opacity-8'
              >
                <span className='fab fa-twitter' />
              </a>
              <a
                href='mailto:admin@chartify.media'
                target='_blank'
                rel='noreferrer'
                className='text-primary opacity-8'
              >
                <span className='fa-solid fa-envelope' />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
