import React from 'react';
import { useSelector } from 'react-redux';
import UpdateCardBack from './UpdateCardBack';
import UpdateCardFront from './UpdateCardFront';
import { useRotateCard } from '../../hooks/useRotateCard';

const UpdateCard = () => {
  // * state
  const { spotify } = useSelector((state) => state);
  const { playlist, playlistURI } = spotify;

  // * hooks
  useRotateCard(true, playlist);
  useRotateCard(false, playlistURI);

  return (
    <div className='col-lg-7 ms-auto mt-lg-0 mt-4 custom-card-container--rotate pb-5'>
      <div className='ms-auto mt-lg-0 mt-4 custom-card--rotate flip'>
        <UpdateCardBack spotify={spotify} />
        <UpdateCardFront spotify={spotify} />
      </div>
    </div>
  );
};

export default UpdateCard;
