import React, { useState } from 'react';
import { useRedirectToPlaylists } from '../../hooks/useRedirectToPlaylists';

const CreatePlaylist = ({ text }) => {
  const [clicked, setClicked] = useState(false);

  useRedirectToPlaylists(clicked);

  const handleClick = () => {
    setClicked(true);
  };

  return (
    <section className='my-5 py-5 mx-n3 bg-gradient-info position-relative overflow-hidden'>
      <div className='container position-relative z-index-2 px-7 '>
        <div className='row'>
          <div className='col-lg-5 col-md-8 m-auto text-start'>
            <h5 className='text-white mb-lg-0 mb-5'>
              {`${text}. Create a new playlist.`}
            </h5>
          </div>
          <div className='col-lg-6 m-auto'>
            <div className='row row-custom'>
              <div className='col-sm-4 col-6 ps-sm-0 ms-auto btn-playlist-custom--margin'>
                <button
                  type='button'
                  className='btn bg-gradient-warning mb-0 mb-sm-0 mb-2 me-auto me-md-4 d-block btn-playlist-custom--center'
                  style={{ width: '200px' }}
                  onClick={handleClick}
                >
                  Create Playlist
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreatePlaylist;
