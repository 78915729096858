import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../functions/formatDate';

const PostHorizontal = ({ article }) => {
  const navigate = useNavigate();
  const handleClick = (pathname) => navigate(pathname);

  return (
    <div className='card card-plain card-blog mt-5'>
      <div className='row'>
        <div className='col-md-4'>
          <div className='card-image position-relative border-radius-lg'>
            <div className='blur-shadow-image'>
              <img
                className='img border-radius-lg post-photo-hor'
                src={article.photoURL}
                alt='description'
              />
            </div>
          </div>
        </div>
        <div className='col-md-7 my-auto ms-md-3 mt-md-auto mt-4'>
          <h3>
            <Link
              to={article.pathname}
              className='text-dark font-weight-normal'
            >
              {article.title}
            </Link>
          </h3>
          <p>
            {article.body.slice(0, 90)}...{' '}
            <span
              href='#'
              className='text-primary article-link'
              onClick={() => handleClick(article.pathname)}
            >
              {' '}
              Read More{' '}
            </span>
          </p>
          <div className='author align-items-center'>
            <img
              src='../../assets/img/graphics/chartify.png'
              alt='...'
              className='avatar shadow'
              loading='lazy'
            />
            <div className='name ps-2'>
              <span>{article.author}</span>
              <div className='stats'>
                <small>{`Posted on ${formatDate(article.date)}`}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostHorizontal;
